import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setState, useGetState } from "./state";
import { useEffect, useState } from "react";
import { ChevronRight } from "@mui/icons-material";

const NewOrgAbout = () => {
  const { isFetching, state } = useGetState();
  const [newOrgAbout, setNewOrgAbout] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (state && state.description) {
      setNewOrgAbout(state.description);
    }
  }, [state]);
  return (
    <Box>
      {isFetching && <CircularProgress />}
      <TextField
        fullWidth
        disabled={isFetching}
        placeholder={`About ${state?.name || "Organization"}`}
        value={newOrgAbout}
        onChange={(e) => setNewOrgAbout(e.target.value)}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
        <Button
          variant="contained"
          disabled={newOrgAbout.length < 10 || isFetching}
          sx={{ mt: 1 }}
          endIcon={<ChevronRight />}
          onClick={() => {
            setState({ description: newOrgAbout });
            navigate("/orgs/new/address");
          }}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default NewOrgAbout;
