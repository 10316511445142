import { ReactElement, useState } from "react";

import { Link as RouterLink } from "react-router-dom";

import { Auth } from "aws-amplify";
import { makeStyles } from "tss-react/mui";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu } from "@mui/icons-material";

const links = [
  {
    label: "Organizations",
    href: "/orgs",
  },
  {
    label: "Settings",
    href: "/settings",
  },
];

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  nav: {
    flexGrow: 1,
    "& > a": {
      color: "#fff",
      marginRight: theme.spacing(2),
    },
  },

  mobileNavTop: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    "& .icon": {
      flexGrow: 1,
    },
  },

  mobileMenu: {
    "& > a": {
      display: "flex",
      flex: 1,
      color: "#fff",
      marginRight: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
}));

const Navigation = (): ReactElement => {
  const { classes } = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);

  const handleSignOutPressed = () => {
    const result = window.confirm("Are you sure you want to sign out?");

    if (result) {
      Auth.signOut();
    }
  };
  if (isMobile) {
    return (
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar>
            <Box sx={{ display: "flex", flex: 1 }}>
              <div className="icon">
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  onClick={() => setIsMobileOpen((v) => !v)}
                  aria-label="menu">
                  <Menu />
                </IconButton>
              </div>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <img
                  src={`${process.env.PUBLIC_URL}/apple-touch-icon.png`}
                  alt="logo"
                  width="50"
                  height="50"
                  style={{ borderRadius: "10px" }}
                />
              </Box>
              <Button style={{ color: "#fff" }} onClick={handleSignOutPressed}>
                Sign Out
              </Button>
            </Box>
          </Toolbar>
          {isMobileOpen && (
            <div className={classes.mobileMenu}>
              {links.map((link) => (
                <RouterLink
                  key={`mobile=${link.href}`}
                  to={link.href}
                  style={{ textDecoration: "none" }}
                  onClick={() => setIsMobileOpen(false)}>
                  {link.label}
                </RouterLink>
              ))}
            </div>
          )}
        </Container>
      </AppBar>
    );
  }
  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar>
          {links.map((link) => (
            <RouterLink
              to={link.href}
              key={`desktop=${link.href}`}
              style={{
                textDecoration: "none",
                color: "white",
                marginRight: "10px",
              }}
              onClick={() => setIsMobileOpen(false)}>
              {link.label}
            </RouterLink>
          ))}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={`${process.env.PUBLIC_URL}/apple-touch-icon.png`}
              alt="logo"
              width="50"
              height="50"
              style={{ borderRadius: "10px" }}
            />
          </Box>
          <Button style={{ color: "#fff" }} onClick={handleSignOutPressed}>
            Sign Out
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
