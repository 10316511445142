import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getOrg, getOrgManagerByUser } from "./backend";
import { SyntheticEvent, useEffect, useState } from "react";
import General from "./Tabs/General";
import Categories from "./Tabs/Categories";
import Volunteers from "./Tabs/Volunteers";
import Reports from "./Tabs/Reports";
import { ChevronLeft } from "@mui/icons-material";
import { Auth } from "aws-amplify";

const useOrg = (orgId?: string | null) => {
  return useQuery(
    ["org", orgId],
    async () => {
      if (!orgId) {
        return null;
      }
      const org = await getOrg({ id: orgId });
      return org;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useOrgManagers = (orgId?: string | null) => {
  return useQuery(["org-managers", orgId], async () => {
    if (!orgId) {
      return [];
    }
    const user = await Auth.currentAuthenticatedUser();
    if (!user) {
      return [];
    }
    const orgManagers = await getOrgManagerByUser({
      organizationId: orgId,
      userId: user.username,
    });
    return orgManagers;
  });
};

const TABS = ["general", "categories", "volunteers", "reports"];

const OrgDisplay = () => {
  const { orgId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { data: org, isLoading } = useOrg(orgId);
  const { data: orgManagers, isLoading: isLoadingOrgManagers } =
    useOrgManagers(orgId);

  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [tabValue, setTabValue] = useState<string>(
    tab && TABS.includes(tab) ? tab : "general",
  );

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    if (!org) {
      return;
    }
    setSearchParams({ tab: newValue });
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!isLoadingOrgManagers && orgManagers) {
      setIsAuthed(true);
    }
  }, [isLoadingOrgManagers, orgManagers]);

  const renderTab = () => {
    if (!org) {
      return null;
    }
    switch (tabValue) {
      case "general":
        return <General />;
      case "categories":
        return <Categories />;
      case "volunteers":
        return <Volunteers />;
      case "reports":
        return <Reports />;
      default:
        return null;
    }
  };

  if (isLoading || isLoadingOrgManagers) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}>
        <CircularProgress />
      </Box>
    );
  }
  if (!isAuthed) {
    return (
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Alert severity="error">
          You are not authorized to view this organization
        </Alert>
      </Container>
    );
  }
  if (!org) {
    return null;
  }
  return (
    <Container sx={{ pt: 2 }}>
      <Button startIcon={<ChevronLeft />} onClick={() => navigate("/orgs")}>
        to All Orgs
      </Button>
      <Tabs
        value={tabValue || "general"}
        orientation={isMobile ? "vertical" : "horizontal"}
        onChange={handleChange}>
        <Tab label="General" value={"general"} />
        <Tab label="Donation Categories" value={"categories"} />
        <Tab label="Volunteers" value={"volunteers"} />
        <Tab label="Reports" value={"reports"} />
      </Tabs>
      <Divider sx={{ my: 2 }} />
      <Box>{renderTab()}</Box>
    </Container>
  );
};

export default OrgDisplay;
