import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  endShift,
  getDonationsForShift,
  getShiftsForOrgByDateRange,
} from "../backend";
import { useParams, useSearchParams } from "react-router-dom";
import { Donation, Shift, ShiftStatus } from "src/API";
import { getDuration, shortDate } from "src/utils/time";
import { useEffect, useState } from "react";
import { add, differenceInDays, format, isValid, sub } from "date-fns";
import { LoadingButton } from "@mui/lab";
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";

const isInvalidDateRange = (startDate: Date, endDate: Date): string | null => {
  const diff = differenceInDays(endDate, startDate);
  if (diff >= 365) {
    return "Date range cannot be more than 1 year";
  }
  if (diff < 0) {
    return "Start date cannot be after end date";
  }
  return null;
};

const useShiftsByDateRange = ({
  orgId,
  startDate,
  endDate,
}: {
  orgId?: string | null;
  startDate: Date;
  endDate: Date;
}) => {
  return useQuery(
    [orgId, startDate, endDate, "shifts"],
    async () => {
      if (!orgId) {
        return [];
      }
      const _startDate = isValid(startDate) ? startDate : new Date();
      const _endDate = isValid(endDate) ? endDate : new Date();
      if (isInvalidDateRange(_startDate, _endDate)) {
        return [];
      }
      const shifts = await getShiftsForOrgByDateRange({
        orgId,
        startDate: _startDate,
        endDate: _endDate,
      });
      return shifts;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const Reports = () => {
  const { orgId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const urlStart = searchParams.get("start");
  const urlEnd = searchParams.get("end");
  const tab = searchParams.get("tab");
  const _activeTab = searchParams.get("activeTab");
  const _filter = searchParams.get("filter");
  const [startDate, setStartDate] = useState<Date>(
    urlStart
      ? add(new Date(urlStart), { days: 1 })
      : sub(new Date(), { days: 7 }),
  );
  const [endDate, setEndDate] = useState<Date>(
    urlEnd ? add(new Date(urlEnd), { days: 1 }) : add(new Date(), { days: 1 }),
  );
  const {
    data: shifts,
    isLoading,
    refetch,
  } = useShiftsByDateRange({
    orgId,
    startDate: startDate,
    endDate: endDate,
  });
  const [activeTab, setActiveTab] = useState(
    _activeTab && !isNaN(parseInt(_activeTab)) ? parseInt(_activeTab) : 0,
  );
  const [rangeError, setRangeError] = useState<string | null>(null);

  const [error, setError] = useState<string | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [viewingShift, setViewingShift] = useState<Shift | null>(null);
  const [isLoadingDonations, setIsLoadingDonations] = useState(false);
  const [viewingShiftDonations, setViewingShiftDonations] = useState<
    Donation[] | null
  >(null);

  const [confirmEndShift, setConfirmEndShift] = useState<Shift | null>(null);

  const [displayShifts, setDisplayShifts] = useState<Shift[] | null>(null);

  // CSV stuff
  const [isDownloading, setIsDownloading] = useState(false);
  const [csvDonationData, setCsvDonationData] = useState<Array<Array<string>>>([
    [],
  ]);
  const [csvShiftData, setCsvShiftData] = useState<Array<Array<string>>>([[]]);
  const [isProcessingDownload, setIsProcessingDownload] = useState(false);

  const filter =
    _filter === "ALL"
      ? "ALL"
      : _filter === "OPEN"
      ? ShiftStatus.OPEN
      : ShiftStatus.CLOSED;
  const [showingShifts, setShowingShifts] = useState<
    "ALL" | ShiftStatus.OPEN | ShiftStatus.CLOSED
  >(filter);

  const [working, setWorking] = useState<boolean>(false);

  const handleEndShift = async (shiftId: string) => {
    try {
      setWorking(true);
      setConfirmEndShift(null);
      await endShift({ shiftId });

      refetch();
    } catch (e) {
      console.error(e);
      setError("Error ending shift");
    } finally {
      setWorking(false);
    }
  };

  const handleDownloadData = async () => {
    try {
      setIsProcessingDownload(true);
      if (displayShifts) {
        const csvDonations: Array<Array<string>> = [];
        const csvShifts: Array<Array<string>> = [];
        displayShifts.forEach((shift: Shift) => {
          const donations = shift.donations?.items || [];
          let counter = 1;
          csvShifts.push([
            shift.id,
            shift.volunteer?.id || "MISSING",
            `${shift.volunteer?.firstName || ""} ${
              shift.volunteer?.lastName || ""
            }`,
            shift.status || "",
            shift.startTime ? shortDate(shift.startTime) : "",
            shift.endTime ? shortDate(shift.endTime) : "",
            shift.donations?.items.length?.toString() || "",
          ]);
          donations.forEach((donation?: Donation | null) => {
            if (!donation) {
              return;
            }
            csvDonations.push([
              `${shift.id}-${counter++}`,
              shift.volunteer?.id || "MISSING",
              `${shift.volunteer?.firstName || ""} ${
                shift.volunteer?.lastName || ""
              }`,
              donation.donorName || "",
              donation.donorEmail || "",
              donation.donorPhone || "",
              donation.donorNeedsReceipt ? "Yes" : "No",
              donation.category?.name || "",
              donation.amount?.toString() || "",
              donation.estimatedValue?.toString() || "",
              donation.donationTime ? shortDate(donation.donationTime) : "",
            ]);
          });
        });
        setCsvDonationData(csvDonations as Array<Array<string>>);
        setCsvShiftData(csvShifts as Array<Array<string>>);
        setIsDownloading(true);
      }
    } catch (_e) {
      const e = _e as Error;
      console.log("[ERROR] error handling downloading data", e);
    } finally {
      setTimeout(() => {
        setIsProcessingDownload(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const _end = isValid(endDate) ? endDate : new Date();
    const _start = isValid(startDate) ? startDate : new Date();
    setRangeError(isInvalidDateRange(_start, _end));

    setSearchParams({
      tab: "reports",
      activeTab: `${activeTab}`,
      start: format(_start, "yyyy-MM-dd"),
      end: format(_end, "yyyy-MM-dd"),
      filter: showingShifts,
    });
  }, [tab, startDate, endDate, setSearchParams, activeTab, showingShifts]);

  useEffect(() => {
    if (!shifts) {
      return;
    }
    if (showingShifts === "ALL") {
      setDisplayShifts(shifts);
    } else if (showingShifts === ShiftStatus.OPEN) {
      setDisplayShifts(
        shifts.filter((shift) => shift.status === ShiftStatus.OPEN),
      );
    } else if (showingShifts === ShiftStatus.CLOSED) {
      setDisplayShifts(
        shifts.filter((shift) => shift.status === ShiftStatus.CLOSED),
      );
    }
  }, [shifts, showingShifts]);

  useEffect(() => {
    const getShiftDonations = async () => {
      try {
        if (!viewingShift) {
          return null;
        }
        setIsLoadingDonations(true);
        const donations = await getDonationsForShift({
          shiftId: viewingShift?.id,
        });

        setViewingShiftDonations(donations);
      } catch (e) {
      } finally {
        setIsLoadingDonations(false);
      }
    };
    if (viewingShift) {
      getShiftDonations();
    }
  }, [viewingShift]);

  const renderShifts = () => {
    if (!shifts) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress />
        </Box>
      );
    }
    if (displayShifts?.length === 0) {
      return (
        <Alert severity="info">
          <Typography variant="h6">
            No shifts found for this date range
          </Typography>
        </Alert>
      );
    }
    return (
      <Grid container spacing={2}>
        {displayShifts?.map((shift: Shift) => {
          const { volunteer, startTime, endTime, status, donations } = shift;
          const totalTime = getDuration({
            startTime,
            endTime,
          });
          const color = status === ShiftStatus.OPEN ? "primary" : "error";
          const fontWeight = status === ShiftStatus.OPEN ? "bold" : "normal";
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={shift.id}>
              <Card>
                <CardActionArea onClick={() => setViewingShift(shift)}>
                  <CardContent>
                    {status && (
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography>Status:</Typography>
                        <Typography
                          color={color}
                          fontWeight={fontWeight}
                          sx={{ ml: 1 }}>
                          {status}
                        </Typography>
                      </Box>
                    )}
                    {startTime && endTime && (
                      <>
                        <Typography>Start: {shortDate(startTime)}</Typography>
                        {status === ShiftStatus.CLOSED && (
                          <Typography component="div">
                            End: {shortDate(endTime)}{" "}
                            {totalTime && `(${totalTime})`}
                          </Typography>
                        )}
                      </>
                    )}
                    {volunteer && (
                      <Typography>
                        {volunteer.firstName} {volunteer.lastName}
                      </Typography>
                    )}
                    {donations && (
                      <Typography
                        sx={{
                          color:
                            donations.items.length === 0 ? "error.main" : "",
                          fontWeight:
                            donations.items.length === 0 ? "bold" : "normal",
                        }}>
                        {donations.items.length}{" "}
                        {donations.items.length === 1
                          ? "donation"
                          : "donations"}
                      </Typography>
                    )}
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  {status === ShiftStatus.OPEN && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setConfirmEndShift(shift);
                      }}>
                      End Shift
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderDonations = () => {
    return (
      <Box>
        {displayShifts?.map((shift: Shift) => {
          if (!shift) {
            return null;
          }
          const { donations } = shift;
          if (!donations) {
            return null;
          }
          return donations.items.map((donation, idx) => {
            if (!donation) {
              return null;
            }
            return (
              <Box key={`donation-${donation.id}-${idx}`}>
                {donation.category && (
                  <Typography>
                    {donation?.category?.name} ({donation?.amount})
                  </Typography>
                )}
                {donation.description && (
                  <Typography>{donation.description}</Typography>
                )}
              </Box>
            );
          });
        })}
      </Box>
    );
  };

  const renderTab = () => {
    if (rangeError) {
      return <Alert severity="error">{rangeError}</Alert>;
    }
    if (activeTab === 0) {
      return renderShifts();
    }
    if (activeTab === 1) {
      return renderDonations();
    }
    return null;
  };

  const date = format(new Date(), "yyyy-MM-dd");

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Tabs
          sx={{ mb: 2 }}
          value={activeTab}
          onChange={(_, v) => setActiveTab(v)}>
          <Tab label="Shifts" value={0} />
          <Tab label="Donations" value={1} />
        </Tabs>
        <Button
          variant="outlined"
          startIcon={<Download />}
          onClick={handleDownloadData}>
          Download Data
        </Button>
      </Box>
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          <Typography variant="h6">{error}</Typography>
        </Alert>
      )}

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth={isMobile}
            value={showingShifts}
            onChange={(e) =>
              setShowingShifts(
                e.target.value as "ALL" | ShiftStatus.OPEN | ShiftStatus.CLOSED,
              )
            }>
            <MenuItem value="ALL">Show All Shifts</MenuItem>
            <MenuItem value={ShiftStatus.OPEN}>Show Open Shifts</MenuItem>
            <MenuItem value={ShiftStatus.CLOSED}>Show Closed Shifts</MenuItem>
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <TextField
              type="date"
              value={format(
                isValid(startDate) ? startDate : new Date(),
                "yyyy-MM-dd",
              )}
              onChange={(e) => {
                const newDate = add(new Date(e.target.value), { days: 1 });
                return setStartDate(newDate);
              }}
              size="small"
              label="Start Date"
              sx={{ mr: 1 }}
            />
            <TextField
              type="date"
              value={format(
                isValid(endDate) ? endDate : new Date(),
                "yyyy-MM-dd",
              )}
              label="End Date"
              size="small"
              onChange={(e) => {
                const newDate = add(new Date(e.target.value), { days: 1 });
                return setEndDate(newDate);
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {renderTab()}
      <Dialog open={!!viewingShift} onClose={() => setViewingShift(null)}>
        <DialogContent>
          {isLoadingDonations && <CircularProgress />}
          {viewingShiftDonations?.length === 0 && (
            <Typography>No Donations</Typography>
          )}
          {!isLoadingDonations && viewingShiftDonations && (
            <Grid container spacing={2}>
              {viewingShiftDonations.map((donation) => {
                const { id } = donation;
                return (
                  <Grid item xs={12} key={id}>
                    <Card>
                      <CardContent>
                        <Typography>Name: {donation.category?.name}</Typography>
                        <Typography>Amount: {donation.amount}</Typography>
                        {donation.description && (
                          <Typography>{donation.description}</Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewingShift(null)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!confirmEndShift} onClose={() => setConfirmEndShift(null)}>
        <DialogTitle>End Shift</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to end this shift?</Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={() => {
              if (!confirmEndShift) {
                return;
              }
              const { id } = confirmEndShift;
              handleEndShift(id);
            }}
            loading={working}
            variant="contained"
            color="error">
            End Shift
          </LoadingButton>
          <Button onClick={() => setConfirmEndShift(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={working}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={isDownloading} onClose={() => setIsDownloading(false)}>
        <DialogTitle>Shift / Donation Data</DialogTitle>
        {isProcessingDownload && (
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <CircularProgress />
              <Typography sx={{ ml: 2 }}>Processing...</Typography>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Box sx={{ display: "flex", flexDirection: "column", p: 3 }}>
            {!isProcessingDownload && (
              <>
                <CSVLink
                  data={csvDonationData}
                  style={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    textDecoration: "none",
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1.75",
                    letterSpacing: "0.02857em",
                    textTransform: "uppercase",
                    padding: "6px 8px",
                    borderRadius: "100px",
                    marginBottom: 10,
                    textAlign: "center",
                    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                  }}
                  headers={[
                    "ID",
                    "Volunteer ID",
                    "Volunteer Name",
                    "Donor Name",
                    "Donor Email",
                    "Donor Phone",
                    "Donor Needs Receipt",
                    "Category",
                    "Amount",
                    "Estimated Value",
                    "Donation Time",
                  ]}
                  filename={`${orgId}-donations-${startDate}-${endDate}-${date}.csv`}>
                  Download Donation Data
                </CSVLink>
                <CSVLink
                  data={csvShiftData}
                  style={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    textDecoration: "none",
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1.75",
                    letterSpacing: "0.02857em",
                    textTransform: "uppercase",
                    padding: "6px 8px",
                    borderRadius: "100px",
                    textAlign: "center",
                    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                  }}
                  headers={[
                    "ID",
                    "Volunteer ID",
                    "Volunteer Name",
                    "Shift Status",
                    "Shift Start",
                    "Shift End",
                    "No. Donations",
                  ]}
                  filename={`${orgId}-shifts-${shortDate(
                    startDate.toDateString() || "",
                  )}-${shortDate(endDate.toDateString() || "")}-${date}.csv`}>
                  Download Shift Data
                </CSVLink>
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => setIsDownloading(false)}>
                  Close
                </Button>
              </>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Reports;
