import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { createUser } from "./backend";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      if (firstName.length > 0 && lastName.length > 0) {
        const result = await createUser({
          firstName,
          lastName,
        });
        if (result) {
          navigate("/");
          return;
        }
        setError("Error creating user");
      } else {
        setError("Please enter a first and last name");
      }
    } catch (e) {
      console.log("[ERROR] error creating user", e);
    }
  };

  useEffect(() => {
    const load = async () => {
      const user = await Auth.currentAuthenticatedUser();

      if (user) {
        setEmail(user.attributes.email);
      }
    };
    load();
  }, []);

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
        }}>
        <Typography sx={{ mb: 2 }} variant="h6">
          Create a new profile
        </Typography>
        <Button
          type="submit"
          color="error"
          variant="outlined"
          onClick={async () => {
            await Auth.signOut();
          }}>
          Sign out
        </Button>
      </Box>

      {error && (
        <Alert sx={{ my: 2 }} severity="error">
          {error}
        </Alert>
      )}

      <TextField
        label="Email"
        disabled
        value={email}
        fullWidth
        sx={{ mb: 2 }}
        variant="outlined"
      />
      <TextField
        variant="outlined"
        autoFocus
        fullWidth
        id="first-name"
        name="firstName"
        label="First Name"
        type="text"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        variant="outlined"
        fullWidth
        id="last-name"
        name="lastName"
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        type="text"
        required
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-betwen" }}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Container>
  );
};

export default Onboarding;
