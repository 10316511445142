import { useCallback, useEffect, useState } from "react";

type State = {
  name?: string;
  description?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  ll?: string;
  phone?: string;
  email?: string;
  website?: string;
  logo?: string | null;
  displayAddress?: string;
};

export const clearState = async () => {
  await localStorage.removeItem("newOrgState");
};

export const setState = async (nextState: Partial<State>) => {
  const storedState = await localStorage.getItem("newOrgState");
  let currState = {};
  if (storedState) {
    currState = {
      ...JSON.parse(storedState),
    };
  }
  currState = {
    ...currState,
    ...nextState,
  };
  await localStorage.setItem("newOrgState", JSON.stringify(currState));
};

export const getState = async () => {
  const storedState = await localStorage.getItem("newOrgState");
  if (storedState) {
    return JSON.parse(storedState);
  }
  return null;
};

export const useGetState = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [state, setState] = useState<State | null>(null);

  const load = async () => {
    setIsFetching(true);
    const newState = await getState();
    setState(newState);
    setIsFetching(false);
  };

  useEffect(() => {
    load();
  }, []);

  const refetch = useCallback(() => {
    load();
  }, []);

  return { isFetching, refetch, state };
};
