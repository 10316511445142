import { API, Auth, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import type { User } from "src/API";
import * as mutations from "./mutations";

export const createUser = async ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): Promise<User | null> => {
  const user = await Auth.currentAuthenticatedUser();
  const email = user.attributes.email;
  const id = user.username;
  const result = (await API.graphql(
    graphqlOperation(mutations.createUser, {
      input: { id, firstName, lastName, email },
    }),
  )) as GraphQLResult<{ createUser: User }>;

  if (result.data?.createUser) {
    return result.data.createUser;
  }
  return null;
};
