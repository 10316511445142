import { Route, Routes } from "react-router-dom";
import { MyOrgs, OrgDisplay } from "src/containers/Organizations";
import NewOrgAbout from "src/containers/Organizations/New/NewOrgAbout";
import NewOrgAddress from "src/containers/Organizations/New/NewOrgAddress";
import NewOrgConfirm from "src/containers/Organizations/New/NewOrgConfirm";
import NewOrgLogo from "src/containers/Organizations/New/NewOrgLogo";
import NewOrgName from "src/containers/Organizations/New/NewOrgName";
import NewOrgWrapper from "src/containers/Organizations/New/NewOrgWrapper";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MyOrgs />} />
      <Route path="orgs">
        <Route path="new" element={<NewOrgWrapper />}>
          <Route index element={<NewOrgName />} />
          <Route path="about" element={<NewOrgAbout />} />
          <Route path="address" element={<NewOrgAddress />} />
          <Route path="logo" element={<NewOrgLogo />} />
          <Route path="confirm" element={<NewOrgConfirm />} />
        </Route>
        <Route path=":orgId" element={<OrgDisplay />} />
        <Route index element={<MyOrgs />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
