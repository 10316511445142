import * as awsConfig from "src/aws-exports";

const { aws_user_files_s3_bucket } = awsConfig.default;

const DEV = aws_user_files_s3_bucket
  ? aws_user_files_s3_bucket.indexOf("dev") > -1
  : false;

const STAGING = aws_user_files_s3_bucket
  ? aws_user_files_s3_bucket.indexOf("staging") > -1
  : false;

const DEV_CLOUD_PREFIX = "https://assets.dev.volunteering.rocks/";
const STAGING_CLOUD_PREFIX = "https://assets.staging.volunteering.rocks/";
const PROD_CLOUD_PREFIX = "https://assets.volunteering.rocks/";

let CLOUD_FORMATION_URL = PROD_CLOUD_PREFIX;
if (DEV) {
  CLOUD_FORMATION_URL = DEV_CLOUD_PREFIX;
} else if (STAGING) {
  CLOUD_FORMATION_URL = STAGING_CLOUD_PREFIX;
}

export const getImageUrl = (path: string): string => {
  return `${CLOUD_FORMATION_URL}${path}`;
};
