export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      deletedAt
    }
  }
`;

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      deletedAt
    }
  }
`;

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      description
      street1
      street2
      city
      state
      zip
      phone
      email
      website
      logo
    }
  }
`;

export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      description
      street1
      street2
      city
      state
      zip
      phone
      email
      website
      logo
      hasCustomDonation
    }
  }
`;

export const updateVolunteerRequest = /* GraphQL */ `
  mutation UpdateVolunteerRequest(
    $input: UpdateVolunteerRequestInput!
    $condition: ModelVolunteerRequestConditionInput
  ) {
    updateVolunteerRequest(input: $input, condition: $condition) {
      id
      message
      status
    }
  }
`;

export const createVolunteer = /* GraphQL */ `
  mutation CreateVolunteer(
    $input: CreateVolunteerInput!
    $condition: ModelVolunteerConditionInput
  ) {
    createVolunteer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      userId
      organizationId
      owner
      status
      managerGroup
      createdAt
      updatedAt
    }
  }
`;

export const updateShift = /* GraphQL */ `
  mutation UpdateShift(
    $input: UpdateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    updateShift(input: $input, condition: $condition) {
      id
      userId
      startTime
      endTime
      status
    }
  }
`;

export const updateVolunteer = /* GraphQL */ `
  mutation UpdateVolunteer(
    $input: UpdateVolunteerInput!
    $condition: ModelVolunteerConditionInput
  ) {
    updateVolunteer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
    }
  }
`;
