import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setState, useGetState } from "./state";
import { useCallback, useEffect, useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export type VenueSelection = {
  description: string;
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  terms: Array<{
    offset: number;
    value: string;
  }>;
  types: Array<string>;
};

const NewOrgAddress = () => {
  const { isFetching, state } = useGetState();
  const [newOrgAddress, setNewOrgAddress] = useState<string>("");
  const navigate = useNavigate();

  const [displayAddress, setDisplayAddress] = useState(
    state?.street1 && state?.city && state?.state
      ? `${state?.street1} ${state?.city}, ${state?.state}`
      : "",
  );
  const [newStreet, setNewStreet] = useState(state?.street1);
  const [newCity, setNewCity] = useState(state?.city);
  const [newState, setNewState] = useState(state?.state);
  const [newLL, setNewLL] = useState(state?.ll);

  const [isProcessing, setIsProcessing] = useState(false);

  // Places searcher
  const {
    suggestions: { loading: placesLoading, data: placeSuggestions },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 750,
  });

  const handleClubAddressChange = useCallback(
    (address: string) => {
      setIsProcessing(true);
      setValue(address);
    },
    [setValue],
  );

  const handleChangeQuery = (value: string) => {
    setDisplayAddress(value);
    handleClubAddressChange(value);
  };

  const handleVenueSelected = async (venue: VenueSelection) => {
    const { types, structured_formatting } = venue;

    let address = "";
    let city = "";
    let state = "";
    const addressParts = structured_formatting.secondary_text.split(",");

    if (types.includes("street_address") || types.includes("premise")) {
      address = structured_formatting.main_text;
      city = addressParts[0] || "";
      state = addressParts[1] || "";
    } else if (types.includes("locality")) {
      city = structured_formatting.main_text;
      state = addressParts[0] || "";
    } else {
      address = addressParts[0] || "";
      city = addressParts[1] || "";
      state = addressParts[2] || "";
    }

    address = address ? address.trim() : "";
    city = city ? city.trim() : "";
    state = state ? state.trim() : "";

    clearSuggestions();

    setDisplayAddress(
      [address, city, state].filter((s) => s.length > 0).join(", "),
    );

    setNewStreet(address);
    setNewCity(city);
    setNewState(state);

    setIsProcessing(true);

    const results = await getGeocode({ address: venue.description });
    if (results.length > 0) {
      try {
        const { lat, lng } = await getLatLng(results[0]);
        setNewLL(`${lat},${lng}`);
      } catch (_e) {
        const e = _e as Error;
        console.log("Error setting address", e);
        window.alert(
          "There was an error setting the address. Please select a new venue.",
        );
      } finally {
        setIsProcessing(false);
      }
    }
  };

  useEffect(() => {
    if (state) {
      console.log("state", state);
      if (state.displayAddress) {
        setDisplayAddress(state.displayAddress);
      } else {
        setDisplayAddress(
          state?.street1 && state?.city && state?.state
            ? `${state?.street1} ${state?.city}, ${state?.state}`
            : "",
        );
      }
    }
  }, [state]);

  useEffect(() => {
    if (isProcessing && placeSuggestions) {
      setIsProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeSuggestions]);

  const renderPlacesResult = () => {
    if (isProcessing || placesLoading) {
      return (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CircularProgress />
        </Box>
      );
    }
    if (placeSuggestions) {
      return (
        <Box>
          {placeSuggestions.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <Button
                key={place_id}
                fullWidth
                variant="outlined"
                sx={{
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  mt: 1,
                }}
                onClick={() => handleVenueSelected(suggestion)}>
                <Typography fontWeight="bold" component="div">
                  {main_text}
                </Typography>
                <Typography variant="caption">({secondary_text})</Typography>
              </Button>
            );
          })}
        </Box>
      );
    }
  };

  useEffect(() => {
    if (state && state.description) {
      setNewOrgAddress(state.description);
    }
  }, [state]);
  return (
    <Box>
      {isFetching && <CircularProgress />}
      <TextField
        fullWidth
        disabled={isFetching}
        placeholder={`Search location or city`}
        value={displayAddress}
        onChange={(e) => handleChangeQuery(e.target.value)}
      />
      {renderPlacesResult()}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
        <Button
          variant="contained"
          disabled={newOrgAddress.length < 10 || isFetching}
          sx={{ mt: 1 }}
          endIcon={<ChevronRight />}
          onClick={() => {
            setState({
              street1: newStreet,
              city: newCity,
              state: newState,
              ll: newLL,
              displayAddress,
            });
            navigate("/orgs/new/logo");
          }}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default NewOrgAddress;
