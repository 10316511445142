import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "src/stores";
import { getVolunteerRequestsForOrg, organizationsByUser } from "./backend";
import { useNavigate } from "react-router-dom";
import { Organization, VolunteerRequest } from "src/API";

const useMyOrgs = (userId: string | null) => {
  return useQuery(["my-orgs", userId], async () => {
    if (!userId) {
      return null;
    }
    const orgs = await organizationsByUser({ userId });

    return orgs;
  });
};

const useOrgNotifs = ({ orgs }: { orgs?: Organization[] | null }) => {
  return useQuery(
    ["org-notifs", orgs],
    async () => {
      if (!orgs?.length) {
        return {};
      }

      // TODO: really need to make this a custom resolver but for now this will work.
      const requests = await Promise.allSettled(
        orgs.map(async (org: Organization) => {
          const orgId = org.id;
          const requests = await getVolunteerRequestsForOrg({ id: orgId });
          return { id: orgId, requests };
        }),
      );

      const requestsByOrg = requests
        ? requests.reduce((acc, cur) => {
            if (!cur || cur.status === "rejected") {
              return acc;
            }
            const val = cur.value;
            if (!val) {
              return acc;
            }
            const { id, requests } = val;
            if (!requests) {
              return acc;
            }
            if (!acc[id]) {
              acc[id] = [];
            }
            acc[id].push(...requests);
            return acc;
          }, {} as Record<string, VolunteerRequest[]>)
        : {};

      return {
        requestsByOrg,
      };
    },
    {
      enabled: !!orgs?.length,
      refetchOnWindowFocus: false,
    },
  );
};

const MyOrgs = () => {
  const { id: userId } = useSelector((state: RootState) => state.user);
  const { data: myOrgs, isLoading } = useMyOrgs(userId);

  const { data: orgNotifs } = useOrgNotifs({ orgs: myOrgs });

  const navigate = useNavigate();

  const renderOrgs = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Grid container spacing={3} pt={3}>
        {myOrgs?.map((org) => {
          const requestsByOrg = orgNotifs?.requestsByOrg;
          const requests = requestsByOrg ? requestsByOrg[org.id] : [];
          return (
            <Grid item key={`org-${org.id}`} xs={12} md={6}>
              <Card>
                <CardActionArea
                  sx={{
                    p: 3,
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                  onClick={() => navigate(`/orgs/${org.id}`)}>
                  {org.logo && (
                    <img
                      src={org.logo}
                      alt={org.name}
                      style={{
                        maxWidth: "150px",
                        maxHeight: "150px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="h6">{org.name}</Typography>
                  </Box>
                </CardActionArea>
                <CardActions>
                  {!!requests?.length && (
                    <Chip
                      onClick={() => {
                        navigate(
                          `/orgs/${org.id}?tab=volunteers&activeTab=pending`,
                        );
                      }}
                      color="primary"
                      label={`${requests.length} new volunteer ${
                        requests.length === 1 ? "request" : "requests"
                      }`}
                    />
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  return (
    <Container sx={{ pt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h1">My Orgs</Typography>
        <Button variant="outlined" onClick={() => navigate("/orgs/new")}>
          Create new Org
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />
      {myOrgs && myOrgs.length === 0 && (
        <Alert severity="info">
          You are not a manager of any organizations
        </Alert>
      )}
      {renderOrgs()}
    </Container>
  );
};

export default MyOrgs;
