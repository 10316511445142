import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import {
  Category,
  Donation,
  Organization,
  OrganizationManager,
  Shift,
  ShiftStatus,
  User,
  Volunteer,
  VolunteerRequest,
  VolunteerRequestStatus,
  VolunteerStatus,
} from "src/API";
import * as queries from "./queries";
import * as mutations from "./mutations";

export const organizationsByUser = async ({
  userId,
}: {
  userId: string;
}): Promise<Organization[] | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.managersByUser, { userId }),
  )) as GraphQLResult<{
    managersByUser: { items: OrganizationManager[] };
  }>;

  if (result.data?.managersByUser?.items) {
    const organizations = result.data.managersByUser.items.map(
      (manager) => manager.organization,
    );
    return organizations.filter(Boolean) as Organization[];
  }
  return null;
};

export const getOrg = async ({
  id,
}: {
  id: string;
}): Promise<Organization | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.getOrganization, { id }),
  )) as GraphQLResult<{
    getOrganization: Organization;
  }>;
  if (result.data?.getOrganization) {
    return result.data.getOrganization;
  }
  return null;
};

export const updateOrg = async ({
  id,
  name,
  description,
  hasCustomDonation,
  logo,
  street1,
  street2,
  city,
  state,
}: {
  id: string;
  name: string | null;
  description?: string | null;
  hasCustomDonation?: boolean | null;
  logo?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
}): Promise<Organization | null> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateOrganization, {
      input: {
        id,
        name,
        description,
        hasCustomDonation,
        logo,
        street1,
        street2,
        city,
        state,
      },
    }),
  )) as GraphQLResult<{
    updateOrganization: Organization;
  }>;
  if (result.data?.updateOrganization) {
    return result.data.updateOrganization;
  }
  return null;
};

export const getCategoriesForOrg = async ({
  id,
}: {
  id: string;
}): Promise<Category[] | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.categoriesByOrganization, {
      organizationId: id,
      deletedAt: { eq: null },
    }),
  )) as GraphQLResult<{
    categoriesByOrganization: { items: Category[] };
  }>;
  if (result.data?.categoriesByOrganization) {
    return result.data.categoriesByOrganization?.items;
  }
  return null;
};

export const updateCategory = async ({
  id,
  name,
  description,
}: {
  id: string;
  name: string | null;
  description?: string | null;
}): Promise<Category | null> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateCategory, {
      input: { id, name, description },
    }),
  )) as GraphQLResult<{
    updateCategory: Category;
  }>;
  if (result.data?.updateCategory) {
    return result.data.updateCategory;
  }
  return null;
};

export const createCategory = async ({
  organizationId,
  name,
}: {
  organizationId: string;
  name: string;
}): Promise<Category | null> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createCategory, {
      input: {
        organizationId,
        name,
        managerGroup: `${organizationId}_managers`,
      },
    }),
  )) as GraphQLResult<{
    createCategory: Category;
  }>;
  if (result.data?.createCategory) {
    return result.data.createCategory;
  }
  return null;
};

export const deleteCategory = async ({
  id,
}: {
  id: string;
}): Promise<Category | null> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateCategory, {
      input: { id, deletedAt: new Date().toISOString() },
    }),
  )) as GraphQLResult<{
    updateCategory: Category;
  }>;
  if (result.data?.updateCategory) {
    return result.data.updateCategory;
  }
  return null;
};

export const restoreCategory = async ({
  id,
}: {
  id: string;
}): Promise<Category | null> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateCategory, {
      input: { id, deletedAt: null },
    }),
  )) as GraphQLResult<{
    updateCategory: Category;
  }>;
  if (result.data?.updateCategory) {
    return result.data.updateCategory;
  }
  return null;
};

export const createOrganization = async ({
  name,
  description,
  street1,
  street2,
  city,
  state,
  zip,
  phone,
  email,
  website,
  logo,
  ll,
}: {
  name: string;
  description?: string | null;
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phone?: string | null;
  email?: string | null;
  website?: string | null;
  logo?: string | null;
  ll?: string | null;
}): Promise<Organization | null> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.createOrganization, {
      input: {
        name,
        description,
        street1,
        street2,
        city,
        state,
        zip,
        phone,
        email,
        website,
        logo,
        ll,
      },
    }),
  )) as GraphQLResult<{
    createOrganization: Organization;
  }>;

  if (result.data?.createOrganization) {
    return result.data.createOrganization;
  }
  return null;
};

export const getVolunteersForOrg = async ({
  id: organizationId,
}: {
  id: string;
}): Promise<Volunteer[] | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.volunteersByOrganizationId, {
      organizationId,
    }),
  )) as GraphQLResult<{
    volunteersByOrganizationId: { items: Volunteer[] };
  }>;
  if (result.data?.volunteersByOrganizationId) {
    return result.data.volunteersByOrganizationId?.items;
  }
  return null;
};

export const getVolunteerRequestsForOrg = async ({
  id: organizationId,
}: {
  id: string;
}): Promise<VolunteerRequest[] | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.volunteerRequestsByOrganizationAndStatus, {
      organizationId,
      status: {
        eq: VolunteerRequestStatus.PENDING,
      },
    }),
  )) as GraphQLResult<{
    volunteerRequestsByOrganizationAndStatus: { items: VolunteerRequest[] };
  }>;
  if (result.data?.volunteerRequestsByOrganizationAndStatus) {
    return result.data.volunteerRequestsByOrganizationAndStatus?.items;
  }
  return null;
};

export const updateVolunteerRequest = async ({
  id,
  status,
}: {
  id: string;
  status: VolunteerRequestStatus;
}): Promise<VolunteerRequest | null> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateVolunteerRequest, {
      input: { id, status },
    }),
  )) as GraphQLResult<{
    updateVolunteerRequest: VolunteerRequest;
  }>;
  if (result.data?.updateVolunteerRequest) {
    return result.data.updateVolunteerRequest;
  }
  return null;
};

export const getOrgManagerByUser = async ({
  userId,
  organizationId,
}: {
  userId: string;
  organizationId: string;
}): Promise<OrganizationManager | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.managersByOrganizationAndUserId, {
      userId: { eq: userId },
      organizationId,
    }),
  )) as GraphQLResult<{
    managersByOrganizationAndUserId: { items: OrganizationManager[] };
  }>;

  if (result.data?.managersByOrganizationAndUserId) {
    return result.data.managersByOrganizationAndUserId?.items[0];
  }
  return null;
};

export const createVolunteer = async ({
  organizationId,
  userId,
}: {
  organizationId: string;
  userId: string;
}): Promise<Volunteer | null> => {
  const user = (await API.graphql(
    graphqlOperation(queries.getUser, { id: userId }),
  )) as GraphQLResult<{ getUser: User }>;

  if (!user.data?.getUser) {
    return null;
  }
  const result = (await API.graphql(
    graphqlOperation(mutations.createVolunteer, {
      input: {
        id: `${organizationId}_${userId}`,
        organizationId,
        userId,
        status: VolunteerStatus.APPROVED,
        managerGroup: `${organizationId}_managers`,
        firstName: user.data.getUser.firstName,
        lastName: user.data.getUser.lastName,
      },
    }),
  )) as GraphQLResult<{
    createVolunteer: Volunteer;
  }>;
  if (result.data?.createVolunteer) {
    return result.data.createVolunteer;
  }
  return null;
};

export const getRecentShiftsForOrg = async ({
  organizationId,
  startDate,
  endDate,
}: {
  organizationId: string;
  startDate?: string;
  endDate?: string;
}): Promise<Shift[] | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.shiftsByOrganizationId, {
      organizationId,
    }),
  )) as GraphQLResult<{
    shiftsByOrganizationId: { items: Shift[] };
  }>;
  if (result.data?.shiftsByOrganizationId) {
    return result.data.shiftsByOrganizationId?.items;
  }
  return null;
};

export const getDonationsForShift = async ({
  shiftId,
}: {
  shiftId: string;
}): Promise<Donation[] | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.donationsByShift, {
      shiftId,
    }),
  )) as GraphQLResult<{
    donationsByShift: { items: Donation[] };
  }>;
  if (result.data?.donationsByShift) {
    return result.data.donationsByShift?.items;
  }
  return null;
};

export const getShiftsForOrgByDateRange = async ({
  orgId,
  startDate,
  endDate,
}: {
  orgId: string;
  startDate: Date;
  endDate: Date;
}): Promise<Shift[] | null> => {
  const shifts = [];
  let nextToken = null;
  do {
    const result = (await API.graphql(
      graphqlOperation(queries.shiftsByOrganizationId, {
        organizationId: orgId,
        filter: {
          startTime: { between: [startDate, endDate] },
        },
        nextToken,
      }),
    )) as GraphQLResult<{
      shiftsByOrganizationId: { items: Shift[]; nextToken?: string | null };
    }>;
    if (result.data?.shiftsByOrganizationId) {
      shifts.push(...result.data.shiftsByOrganizationId?.items);
      nextToken = result.data.shiftsByOrganizationId?.nextToken;
    } else {
      nextToken = null;
    }
    nextToken = null;
  } while (nextToken);
  return shifts;
};

export const endShift = async ({
  shiftId,
}: {
  shiftId: string;
}): Promise<Shift | null> => {
  const shift = (await API.graphql(
    graphqlOperation(queries.getShift, { id: shiftId }),
  )) as GraphQLResult<{ getShift: Shift }>;
  if (!shift.data?.getShift) {
    throw new Error("Shift not found");
  }
  const startTime = shift.data.getShift.startTime;
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.updateShift, {
        input: {
          id: shiftId,
          endTime: new Date().toISOString(),
          startTime,
          status: ShiftStatus.CLOSED,
        },
      }),
    )) as GraphQLResult<{
      updateShift: Shift;
    }>;
    if (result.data?.updateShift) {
      return result.data.updateShift;
    }
  } catch (e) {
    console.log("[ERROR] error ending shift", e);
  }
  throw new Error("Failed to end shift");
};

export const saveVolunteer = async ({
  id,
  firstName,
  lastName,
  email,
}: {
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
}): Promise<null | Volunteer> => {
  const result = (await API.graphql(
    graphqlOperation(mutations.updateVolunteer, {
      input: {
        id,
        firstName,
        lastName,
        email,
      },
    }),
  )) as GraphQLResult<{
    updateVolunteer: Volunteer;
  }>;
  if (result.data?.updateVolunteer) {
    return result.data.updateVolunteer;
  }
  return null;
};
