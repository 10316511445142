import { Auth, Storage } from "aws-amplify";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CenterModal from "./CenterModal";
import {
  Box,
  Button,
  LinearProgress,
  LinearProgressProps,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

type Props = {
  onComplete: (croppedImage: string) => void;
  onCancel: () => void;
  keyFolder?: string;
  aspect?: number;
  containerSx?: SxProps<Theme>;
  imageSx?: SxProps<Theme>;
  showPreviewSquare?: boolean;
  showPreviewCircle?: boolean;
};

const PhotoCropper = ({
  onComplete,
  onCancel,
  keyFolder,
  aspect: _aspect,
  containerSx,
  imageSx,
  showPreviewSquare,
  showPreviewCircle,
}: Props): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [src, setSrc] = useState<ArrayBuffer | string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const [croppedBlob, setCroppedBlob] = useState<Blob | null>(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [imageType, setImageType] = useState<string | null>("image/jpeg");

  const aspect = _aspect ? _aspect : 1;

  const mobileDivisor = isMobile ? 1.5 : 1;

  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    width: 200 / mobileDivisor,
    height: 200 / aspect / mobileDivisor,
    x: 0,
    y: 0,
  });

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const f = e.target.files[0];

      setImageType(f.type);

      const img = new Image();
      img.src = URL.createObjectURL(f);
      img.onload = function () {
        // @ts-ignore
        const w = this.width;
        // @ts-ignore
        const h = this.height;
        const widthDivisor = w > h ? h / w : 1;
        const maxSize = Math.min(300, w, h);
        const newCrop = {
          ...crop,
          x: 0,
          y: 0,
          height: (maxSize * widthDivisor) / aspect / mobileDivisor,
          width: (maxSize * widthDivisor) / mobileDivisor,
        };
        setCrop(newCrop);
      };
      setSelectedFile(f);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (reader.result) {
          setSrc(reader.result);
          setIsCropping(true);
        }
      });
      reader.readAsDataURL(f);
    }
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRef(image);
  };
  const onCropComplete = useCallback(
    async (crop: Crop) => {
      if (imageRef && crop.width && crop.height) {
        const fileType = imageType === "image/png" ? "png" : "jpeg";
        const croppedImageUrl = await getCroppedImg(
          imageRef,
          crop,
          `newFile.${fileType}`,
        );
        if (croppedImageUrl) {
          setCroppedImage(croppedImageUrl);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageRef],
  );
  const onCropChange = (_crop: Crop) => {
    setCrop(_crop);
  };

  const handleCancelCrop = () => {
    if (onCancel) {
      onCancel();
    }
    setCroppedImage(null);
    setIsCropping(false);
    setImageRef(null);
  };

  const getCroppedImg = (
    image: HTMLImageElement,
    crop: Crop,
    fileName: string,
  ): Promise<string | undefined> => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    if (!ctx) {
      return Promise.resolve(undefined);
    }

    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          // @ts-ignore
          blob.name = fileName;
          const urlCreator = window.URL || window.webkitURL;
          if (croppedImage) {
            urlCreator.revokeObjectURL(croppedImage);
          }

          setCroppedBlob(blob);

          const objectUrl = urlCreator.createObjectURL(blob);

          resolve(objectUrl || "");
        },
        imageType || "image/jpeg",
        1,
      );
    });
  };

  const handleCropPhoto = async () => {
    try {
      if (!selectedFile || !croppedBlob) {
        console.log("[DEBUG] no file or blob", selectedFile, croppedBlob);
        return;
      }
      setIsUploading(true);
      const user = await Auth.currentAuthenticatedUser();
      const date = new Date();
      const keyStart = keyFolder ? keyFolder : "misc";
      const fileType = imageType === "image/png" ? "png" : "jpeg";
      const uploadKey = `${keyStart}/${
        user.attributes.sub
      }-${+date}.${fileType}`;
      const newFile = new File([croppedBlob], selectedFile.name);
      const storageResp = (await Storage.put(uploadKey, newFile, {
        level: "public",
        contentType: imageType || "image/jpeg",
        progressCallback(progress) {
          const progressAmount = Math.round(
            (progress.loaded / (progress.total + 1)) * 100,
          );
          setProgress(progressAmount);
        },
      })) as { key: string };

      const { key } = storageResp;

      onComplete(key);
      setImageRef(null);

      setIsCropping(false);
    } catch (e) {
      console.log("[ERROR] error uploading image", e);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (imageRef) {
      onCropComplete(crop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageRef, onCropComplete]);

  return (
    <Box>
      {!imageRef && (
        <label htmlFor="contained-button-file">
          <input
            accept="image/*"
            id="contained-button-file"
            onChange={onSelectFile}
            type="file"
          />
        </label>
      )}
      <CenterModal open={isCropping}>
        <Box
          sx={{
            ...containerSx,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}>
            {src && (
              <Box sx={imageSx}>
                <ReactCrop
                  style={{
                    maxWidth: "100%",
                    maxHeight: isMobile ? "50vh" : "80vh",
                    height: "auto",
                  }}
                  crop={crop}
                  aspect={_aspect}
                  onComplete={onCropComplete}
                  onChange={onCropChange}>
                  <img
                    src={src.toString()}
                    onLoad={(e) => onImageLoaded(e.target as HTMLImageElement)}
                    alt="crop"
                  />
                </ReactCrop>
              </Box>
            )}
            {(showPreviewSquare || showPreviewCircle) && (
              <Box
                sx={{
                  marginLeft: { xs: 0, md: 1 },
                  border: "1px #f0f0f0 solid",
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: { xs: "space-evenly", md: "flex-start" },
                }}>
                <Typography sx={{ textAlign: "center" }} variant="h6">
                  Preview
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", md: "column" },
                    justifyContent: "center",
                  }}>
                  {croppedImage && showPreviewSquare && (
                    <img
                      src={croppedImage}
                      style={{
                        width: 100 / mobileDivisor,
                        maxHeight: 300 / mobileDivisor,
                        objectFit: "contain",
                      }}
                      alt="cropped"
                    />
                  )}
                  {croppedImage && showPreviewCircle && (
                    <img
                      src={croppedImage}
                      style={{
                        borderRadius: 50,
                        marginTop: 5,
                        width: 100 / mobileDivisor,
                        maxHeight: 300 / mobileDivisor,
                        objectFit: "contain",
                      }}
                      alt="cropped circle"
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
          {progress > 0 && progress < 100 && (
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          )}
          <Button
            style={{ marginTop: 10, borderRadius: 50 }}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isUploading}
            onClick={handleCropPhoto}>
            Crop
          </Button>
          <Button onClick={handleCancelCrop} color="secondary">
            Cancel
          </Button>
        </Box>
      </CenterModal>
    </Box>
  );
};

export default PhotoCropper;
