import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type UserState = {
  id: string | null;
  isNewUser: boolean;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
};

const initialState: UserState = {
  id: null,
  isNewUser: false,
  firstName: "",
  lastName: "",
  profilePictureUrl: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => {
      Object.keys(initialState).forEach((key) => {
        // @ts-ignore
        state[key] = initialState[key];
      });
    },
    setIsNewUser: (state) => {
      state.isNewUser = true;
    },
    setUser: (state, action: PayloadAction<UserState>) => {
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.isNewUser = false;
      state.profilePictureUrl =
        action.payload.profilePictureUrl || state.profilePictureUrl;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetUser, setUser, setIsNewUser } = userSlice.actions;

export default userSlice.reducer;
