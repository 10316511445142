import { useEffect, useState, ReactElement } from "react";
import { Amplify, Hub, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import MainApp from "./containers/MainApp";
import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { Authenticator, Theme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import theme from "./theme";

import { ThemeProvider as AwsThemeProvider } from "@aws-amplify/ui-react";

Amplify.configure(awsconfig);

const awsTheme: Theme = {
  name: "my-theme",
  tokens: {
    colors: {
      font: {
        primary: { value: "#252525" },
        // ...
      },
    },
    components: {
      button: {
        borderRadius: { value: "100px" },
        primary: {
          backgroundColor: { value: theme.palette.primary.main },
        },
        link: {
          color: { value: theme.palette.primary.main },
          borderWidth: { value: "1px" },
          borderColor: { value: "#ff5" },
          _hover: {
            borderColor: { value: theme.palette.primary.main },
          },
        },
      },
      tabs: {
        item: {
          _active: {
            color: { value: theme.palette.primary.main },
          },
        },
      },
    },
  },
};

const App = (): ReactElement => {
  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadUser = async () => {
    try {
      setIsLoading(true);
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        setIsAuthed(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsAuthed(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Hub.listen("auth", (authEvent) => {
      const { payload } = authEvent;
      const { event } = payload;
      if (event === "signIn") {
        loadUser();
      } else if (event === "cognitoHostedUI") {
        setIsAuthed(true);
      } else if (event === "signOut") {
        setIsAuthed(false);
      }
    });
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  const renderMainApp = () => {
    if (isAuthed) {
      return <MainApp />;
    } else {
      return (
        <AwsThemeProvider theme={awsTheme}>
          <Authenticator loginMechanisms={["email"]}>
            <Box>Box</Box>
          </Authenticator>
        </AwsThemeProvider>
      );
    }
  };

  if (isLoading) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "40vh",
          alignItems: "center",
        }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>{renderMainApp()}</ThemeProvider>
    </>
  );
};

export default App;
