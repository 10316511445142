export const managersByUser = /* GraphQL */ `
  query ManagersByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    managersByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        organization {
          id
          name
          logo
          hasCustomDonation
        }
      }
      nextToken
    }
  }
`;

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      street1
      street2
      city
      state
      zip
      phone
      email
      website
      logo
      hasCustomDonation
    }
  }
`;

export const categoriesByOrganizationAndDeletedAt = /* GraphQL */ `
  query CategoriesByOrganizationAndDeletedAt(
    $organizationId: ID!
    $deletedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesByOrganizationAndDeletedAt(
      organizationId: $organizationId
      deletedAt: $deletedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        deletedAt
      }
      nextToken
    }
  }
`;

export const categoriesByOrganization = /* GraphQL */ `
  query CategoriesByOrganization(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesByOrganization(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        deletedAt
      }
      nextToken
    }
  }
`;

export const volunteersByOrganizationId = /* GraphQL */ `
  query VolunteersByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVolunteerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    volunteersByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        userId
        createdAt
      }
      nextToken
    }
  }
`;

export const volunteerRequestsByOrganizationAndStatus = /* GraphQL */ `
  query VolunteerRequestsByOrganizationAndStatus(
    $organizationId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVolunteerRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    volunteerRequestsByOrganizationAndStatus(
      organizationId: $organizationId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        status
        userId
        user {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const managersByOrganizationAndUserId = /* GraphQL */ `
  query ManagersByOrganizationAndUserId(
    $organizationId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    managersByOrganizationAndUserId(
      organizationId: $organizationId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      createdAt
      updatedAt
    }
  }
`;

export const shiftsByOrganizationId = /* GraphQL */ `
  query ShiftsByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shiftsByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        startTime
        endTime
        status
        volunteerId
        volunteer {
          id
          firstName
          lastName
          createdAt
          updatedAt
        }
        donations {
          items {
            id
            donationTime
            description
            amount
            deletedAt
            donorName
            donorEmail
            donorPhone
            donorNeedsReceipt
            category {
              id
              name
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const donationsByShift = /* GraphQL */ `
  query DonationsByShift(
    $shiftId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDonationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    donationsByShift(
      shiftId: $shiftId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        donorName
        donorEmail
        donorPhone
        donorNeedsReceipt
        donationTime
        description
        amount
        deletedAt
        user {
          id
          firstName
          lastName
        }
        category {
          id
          name
          deletedAt
        }
      }
      nextToken
    }
  }
`;

export const getShift = /* GraphQL */ `
  query GetShift($id: ID!) {
    getShift(id: $id) {
      id
      userId
      startTime
      endTime
      status
    }
  }
`;
