import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { clearState } from "./state";

const NAME = "/orgs/new";
const ABOUT = "/orgs/new/about";
const ADDRESS = "/orgs/new/address";
const LOGO = "/orgs/new/logo";
const CONFIRM = "/orgs/new/confirm";

const back = {
  [NAME]: null,
  [ABOUT]: NAME,
  [ADDRESS]: ABOUT,
  [LOGO]: ADDRESS,
  [CONFIRM]: LOGO,
};

const names = {
  [NAME]: "Name",
  [ABOUT]: "About",
  [ADDRESS]: "Address",
  [LOGO]: "Logo",
  [CONFIRM]: "Confirm",
};

const NewOrgWrapper = () => {
  const location = useLocation();

  const pathName = location.pathname;

  const navigate = useNavigate();

  const prev = back[pathName as keyof typeof back];
  const prevName = names[prev as keyof typeof names];
  return (
    <Box>
      <Typography variant="h4" sx={{ my: 2 }}>
        New Org | {names[pathName as keyof typeof names]}
      </Typography>
      {prev && (
        <Button
          startIcon={<ChevronLeft />}
          sx={{ my: 2 }}
          onClick={() => navigate(prev)}>
          Back to {prevName}
        </Button>
      )}
      <Outlet />

      <Button
        onClick={async () => {
          await clearState();
          navigate("/orgs");
        }}>
        Cancel
      </Button>
    </Box>
  );
};

export default NewOrgWrapper;
