/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  profileImageUrl?: string | null,
  email?: string | null,
  phone?: string | null,
  owner?: string | null,
};

export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  profileImageUrl?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type User = {
  __typename: "User",
  id: string,
  firstName: string,
  lastName: string,
  profileImageUrl?: string | null,
  email?: string | null,
  phone?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  profileImageUrl?: string | null,
  email?: string | null,
  phone?: string | null,
  owner?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateVolunteerInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email?: string | null,
  phone?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  owner?: string | null,
  status?: VolunteerStatus | null,
  managerGroup?: string | null,
};

export enum VolunteerStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export type ModelVolunteerConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  status?: ModelVolunteerStatusInput | null,
  managerGroup?: ModelStringInput | null,
  and?: Array< ModelVolunteerConditionInput | null > | null,
  or?: Array< ModelVolunteerConditionInput | null > | null,
  not?: ModelVolunteerConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelVolunteerStatusInput = {
  eq?: VolunteerStatus | null,
  ne?: VolunteerStatus | null,
};

export type Volunteer = {
  __typename: "Volunteer",
  id: string,
  firstName: string,
  lastName: string,
  email?: string | null,
  phone?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  user?: User | null,
  organization?: Organization | null,
  owner?: string | null,
  status?: VolunteerStatus | null,
  managerGroup?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name: string,
  description?: string | null,
  street1?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  phone?: string | null,
  email?: string | null,
  website?: string | null,
  logo?: string | null,
  hasCustomDonation?: boolean | null,
  managerGroup?: string | null,
  status?: OrganizationStatus | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum OrganizationStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  HIDDEN = "HIDDEN",
}


export type UpdateVolunteerInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  owner?: string | null,
  status?: VolunteerStatus | null,
  managerGroup?: string | null,
};

export type DeleteVolunteerInput = {
  id: string,
};

export type CreateShiftInput = {
  id?: string | null,
  userId?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  status?: ShiftStatus | null,
  managerGroup?: string | null,
  volunteerId?: string | null,
  organizationId?: string | null,
};

export enum ShiftStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  UPCOMING = "UPCOMING",
}


export type ModelShiftConditionInput = {
  userId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  status?: ModelShiftStatusInput | null,
  managerGroup?: ModelStringInput | null,
  volunteerId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  and?: Array< ModelShiftConditionInput | null > | null,
  or?: Array< ModelShiftConditionInput | null > | null,
  not?: ModelShiftConditionInput | null,
};

export type ModelShiftStatusInput = {
  eq?: ShiftStatus | null,
  ne?: ShiftStatus | null,
};

export type Shift = {
  __typename: "Shift",
  id: string,
  userId?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  status?: ShiftStatus | null,
  managerGroup?: string | null,
  volunteerId?: string | null,
  organizationId?: string | null,
  organization?: Organization | null,
  user?: User | null,
  volunteer?: Volunteer | null,
  donations?: ModelDonationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelDonationConnection = {
  __typename: "ModelDonationConnection",
  items:  Array<Donation | null >,
  nextToken?: string | null,
};

export type Donation = {
  __typename: "Donation",
  id: string,
  userId?: string | null,
  organizationId?: string | null,
  volunteerId?: string | null,
  categoryId?: string | null,
  shiftId?: string | null,
  donationTime?: string | null,
  description?: string | null,
  estimatedValue?: number | null,
  amount: number,
  deletedAt?: string | null,
  category?: Category | null,
  organization?: Organization | null,
  volunteer?: Volunteer | null,
  user?: User | null,
  shift?: Shift | null,
  donorName?: string | null,
  donorEmail?: string | null,
  donorPhone?: string | null,
  donorNeedsReceipt?: boolean | null,
  managerGroup?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Category = {
  __typename: "Category",
  id: string,
  userId?: string | null,
  name: string,
  parentCategoryId?: string | null,
  parentCategory?: Category | null,
  organizationId?: string | null,
  organization?: Organization | null,
  managerGroup?: string | null,
  deletedAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateShiftInput = {
  id: string,
  userId?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  status?: ShiftStatus | null,
  managerGroup?: string | null,
  volunteerId?: string | null,
  organizationId?: string | null,
};

export type DeleteShiftInput = {
  id: string,
};

export type CreateOrganizationManagerInput = {
  id?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  status?: ManagerStatus | null,
  managerGroup?: string | null,
  owner?: string | null,
};

export enum ManagerStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export type ModelOrganizationManagerConditionInput = {
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  status?: ModelManagerStatusInput | null,
  managerGroup?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelOrganizationManagerConditionInput | null > | null,
  or?: Array< ModelOrganizationManagerConditionInput | null > | null,
  not?: ModelOrganizationManagerConditionInput | null,
};

export type ModelManagerStatusInput = {
  eq?: ManagerStatus | null,
  ne?: ManagerStatus | null,
};

export type OrganizationManager = {
  __typename: "OrganizationManager",
  id: string,
  userId?: string | null,
  organizationId?: string | null,
  user?: User | null,
  organization?: Organization | null,
  status?: ManagerStatus | null,
  managerGroup?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOrganizationManagerInput = {
  id: string,
  userId?: string | null,
  organizationId?: string | null,
  status?: ManagerStatus | null,
  managerGroup?: string | null,
  owner?: string | null,
};

export type DeleteOrganizationManagerInput = {
  id: string,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  street1?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  phone?: string | null,
  email?: string | null,
  website?: string | null,
  logo?: string | null,
  hasCustomDonation?: boolean | null,
  managerGroup?: string | null,
  status?: OrganizationStatus | null,
  owner?: string | null,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  street1?: ModelStringInput | null,
  street2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  website?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  hasCustomDonation?: ModelBooleanInput | null,
  managerGroup?: ModelStringInput | null,
  status?: ModelOrganizationStatusInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelOrganizationStatusInput = {
  eq?: OrganizationStatus | null,
  ne?: OrganizationStatus | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  street1?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  phone?: string | null,
  email?: string | null,
  website?: string | null,
  logo?: string | null,
  hasCustomDonation?: boolean | null,
  managerGroup?: string | null,
  status?: OrganizationStatus | null,
  owner?: string | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  userId?: string | null,
  name: string,
  parentCategoryId?: string | null,
  organizationId?: string | null,
  managerGroup?: string | null,
  deletedAt?: string | null,
};

export type ModelCategoryConditionInput = {
  userId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  parentCategoryId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  managerGroup?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryInput = {
  id: string,
  userId?: string | null,
  name?: string | null,
  parentCategoryId?: string | null,
  organizationId?: string | null,
  managerGroup?: string | null,
  deletedAt?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateDonaterInfoInput = {
  id?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  wantsReceipt?: boolean | null,
  donationId?: string | null,
};

export type ModelDonaterInfoConditionInput = {
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  wantsReceipt?: ModelBooleanInput | null,
  donationId?: ModelIDInput | null,
  and?: Array< ModelDonaterInfoConditionInput | null > | null,
  or?: Array< ModelDonaterInfoConditionInput | null > | null,
  not?: ModelDonaterInfoConditionInput | null,
};

export type DonaterInfo = {
  __typename: "DonaterInfo",
  id: string,
  userId?: string | null,
  organizationId?: string | null,
  user?: User | null,
  organization?: Organization | null,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  wantsReceipt?: boolean | null,
  donationId?: string | null,
  donation?: Donation | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDonaterInfoInput = {
  id: string,
  userId?: string | null,
  organizationId?: string | null,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  wantsReceipt?: boolean | null,
  donationId?: string | null,
};

export type DeleteDonaterInfoInput = {
  id: string,
};

export type CreateDonationInput = {
  id?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  volunteerId?: string | null,
  categoryId?: string | null,
  shiftId?: string | null,
  donationTime?: string | null,
  description?: string | null,
  estimatedValue?: number | null,
  amount: number,
  deletedAt?: string | null,
  donorName?: string | null,
  donorEmail?: string | null,
  donorPhone?: string | null,
  donorNeedsReceipt?: boolean | null,
  managerGroup?: string | null,
};

export type ModelDonationConditionInput = {
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  volunteerId?: ModelIDInput | null,
  categoryId?: ModelIDInput | null,
  shiftId?: ModelIDInput | null,
  donationTime?: ModelStringInput | null,
  description?: ModelStringInput | null,
  estimatedValue?: ModelFloatInput | null,
  amount?: ModelFloatInput | null,
  deletedAt?: ModelStringInput | null,
  donorName?: ModelStringInput | null,
  donorEmail?: ModelStringInput | null,
  donorPhone?: ModelStringInput | null,
  donorNeedsReceipt?: ModelBooleanInput | null,
  managerGroup?: ModelStringInput | null,
  and?: Array< ModelDonationConditionInput | null > | null,
  or?: Array< ModelDonationConditionInput | null > | null,
  not?: ModelDonationConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateDonationInput = {
  id: string,
  userId?: string | null,
  organizationId?: string | null,
  volunteerId?: string | null,
  categoryId?: string | null,
  shiftId?: string | null,
  donationTime?: string | null,
  description?: string | null,
  estimatedValue?: number | null,
  amount?: number | null,
  deletedAt?: string | null,
  donorName?: string | null,
  donorEmail?: string | null,
  donorPhone?: string | null,
  donorNeedsReceipt?: boolean | null,
  managerGroup?: string | null,
};

export type DeleteDonationInput = {
  id: string,
};

export type CreatePotentialUserInput = {
  id?: string | null,
  email: string,
};

export type ModelPotentialUserConditionInput = {
  email?: ModelStringInput | null,
  and?: Array< ModelPotentialUserConditionInput | null > | null,
  or?: Array< ModelPotentialUserConditionInput | null > | null,
  not?: ModelPotentialUserConditionInput | null,
};

export type PotentialUser = {
  __typename: "PotentialUser",
  id: string,
  email: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePotentialUserInput = {
  id: string,
  email?: string | null,
};

export type DeletePotentialUserInput = {
  id: string,
};

export type CreateVolunteerRequestInput = {
  id?: string | null,
  message?: string | null,
  status?: VolunteerRequestStatus | null,
  managerGroup?: string | null,
  userId: string,
  organizationId: string,
};

export enum VolunteerRequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export type ModelVolunteerRequestConditionInput = {
  message?: ModelStringInput | null,
  status?: ModelVolunteerRequestStatusInput | null,
  managerGroup?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  and?: Array< ModelVolunteerRequestConditionInput | null > | null,
  or?: Array< ModelVolunteerRequestConditionInput | null > | null,
  not?: ModelVolunteerRequestConditionInput | null,
};

export type ModelVolunteerRequestStatusInput = {
  eq?: VolunteerRequestStatus | null,
  ne?: VolunteerRequestStatus | null,
};

export type VolunteerRequest = {
  __typename: "VolunteerRequest",
  id: string,
  message?: string | null,
  status?: VolunteerRequestStatus | null,
  managerGroup?: string | null,
  userId: string,
  organizationId: string,
  user?: User | null,
  organization?: Organization | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVolunteerRequestInput = {
  id: string,
  message?: string | null,
  status?: VolunteerRequestStatus | null,
  managerGroup?: string | null,
  userId?: string | null,
  organizationId?: string | null,
};

export type DeleteVolunteerRequestInput = {
  id: string,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  profileImageUrl?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelVolunteerFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  status?: ModelVolunteerStatusInput | null,
  managerGroup?: ModelStringInput | null,
  and?: Array< ModelVolunteerFilterInput | null > | null,
  or?: Array< ModelVolunteerFilterInput | null > | null,
  not?: ModelVolunteerFilterInput | null,
};

export type ModelVolunteerConnection = {
  __typename: "ModelVolunteerConnection",
  items:  Array<Volunteer | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelShiftFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  status?: ModelShiftStatusInput | null,
  managerGroup?: ModelStringInput | null,
  volunteerId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  and?: Array< ModelShiftFilterInput | null > | null,
  or?: Array< ModelShiftFilterInput | null > | null,
  not?: ModelShiftFilterInput | null,
};

export type ModelShiftConnection = {
  __typename: "ModelShiftConnection",
  items:  Array<Shift | null >,
  nextToken?: string | null,
};

export type ModelShiftByOrganizationIdAndStartAndEndCompositeKeyConditionInput = {
  eq?: ModelShiftByOrganizationIdAndStartAndEndCompositeKeyInput | null,
  le?: ModelShiftByOrganizationIdAndStartAndEndCompositeKeyInput | null,
  lt?: ModelShiftByOrganizationIdAndStartAndEndCompositeKeyInput | null,
  ge?: ModelShiftByOrganizationIdAndStartAndEndCompositeKeyInput | null,
  gt?: ModelShiftByOrganizationIdAndStartAndEndCompositeKeyInput | null,
  between?: Array< ModelShiftByOrganizationIdAndStartAndEndCompositeKeyInput | null > | null,
  beginsWith?: ModelShiftByOrganizationIdAndStartAndEndCompositeKeyInput | null,
};

export type ModelShiftByOrganizationIdAndStartAndEndCompositeKeyInput = {
  startTime?: string | null,
  endTime?: string | null,
};

export type ModelOrganizationManagerFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  status?: ModelManagerStatusInput | null,
  managerGroup?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelOrganizationManagerFilterInput | null > | null,
  or?: Array< ModelOrganizationManagerFilterInput | null > | null,
  not?: ModelOrganizationManagerFilterInput | null,
};

export type ModelOrganizationManagerConnection = {
  __typename: "ModelOrganizationManagerConnection",
  items:  Array<OrganizationManager | null >,
  nextToken?: string | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  street1?: ModelStringInput | null,
  street2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  website?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  hasCustomDonation?: ModelBooleanInput | null,
  managerGroup?: ModelStringInput | null,
  status?: ModelOrganizationStatusInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  parentCategoryId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  managerGroup?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelDonaterInfoFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  wantsReceipt?: ModelBooleanInput | null,
  donationId?: ModelIDInput | null,
  and?: Array< ModelDonaterInfoFilterInput | null > | null,
  or?: Array< ModelDonaterInfoFilterInput | null > | null,
  not?: ModelDonaterInfoFilterInput | null,
};

export type ModelDonaterInfoConnection = {
  __typename: "ModelDonaterInfoConnection",
  items:  Array<DonaterInfo | null >,
  nextToken?: string | null,
};

export type ModelDonationFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  volunteerId?: ModelIDInput | null,
  categoryId?: ModelIDInput | null,
  shiftId?: ModelIDInput | null,
  donationTime?: ModelStringInput | null,
  description?: ModelStringInput | null,
  estimatedValue?: ModelFloatInput | null,
  amount?: ModelFloatInput | null,
  deletedAt?: ModelStringInput | null,
  donorName?: ModelStringInput | null,
  donorEmail?: ModelStringInput | null,
  donorPhone?: ModelStringInput | null,
  donorNeedsReceipt?: ModelBooleanInput | null,
  managerGroup?: ModelStringInput | null,
  and?: Array< ModelDonationFilterInput | null > | null,
  or?: Array< ModelDonationFilterInput | null > | null,
  not?: ModelDonationFilterInput | null,
};

export type ModelPotentialUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelPotentialUserFilterInput | null > | null,
  or?: Array< ModelPotentialUserFilterInput | null > | null,
  not?: ModelPotentialUserFilterInput | null,
};

export type ModelPotentialUserConnection = {
  __typename: "ModelPotentialUserConnection",
  items:  Array<PotentialUser | null >,
  nextToken?: string | null,
};

export type ModelVolunteerRequestFilterInput = {
  id?: ModelIDInput | null,
  message?: ModelStringInput | null,
  status?: ModelVolunteerRequestStatusInput | null,
  managerGroup?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  and?: Array< ModelVolunteerRequestFilterInput | null > | null,
  or?: Array< ModelVolunteerRequestFilterInput | null > | null,
  not?: ModelVolunteerRequestFilterInput | null,
};

export type ModelVolunteerRequestConnection = {
  __typename: "ModelVolunteerRequestConnection",
  items:  Array<VolunteerRequest | null >,
  nextToken?: string | null,
};

export type FindableUserConnection = {
  __typename: "FindableUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type FindableOrganizationConnection = {
  __typename: "FindableOrganizationConnection",
  items?:  Array<Organization | null > | null,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelSubscriptionDonaterInfoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  wantsReceipt?: ModelSubscriptionBooleanInput | null,
  donationId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionDonaterInfoFilterInput | null > | null,
  or?: Array< ModelSubscriptionDonaterInfoFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionDonationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  volunteerId?: ModelSubscriptionIDInput | null,
  categoryId?: ModelSubscriptionIDInput | null,
  shiftId?: ModelSubscriptionIDInput | null,
  donationTime?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  estimatedValue?: ModelSubscriptionFloatInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  donorName?: ModelSubscriptionStringInput | null,
  donorEmail?: ModelSubscriptionStringInput | null,
  donorPhone?: ModelSubscriptionStringInput | null,
  donorNeedsReceipt?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionDonationFilterInput | null > | null,
  or?: Array< ModelSubscriptionDonationFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPotentialUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPotentialUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionPotentialUserFilterInput | null > | null,
};

export type ModelSubscriptionVolunteerRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionVolunteerRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionVolunteerRequestFilterInput | null > | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    profileImageUrl?: string | null,
    email?: string | null,
    phone?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    profileImageUrl?: string | null,
    email?: string | null,
    phone?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    profileImageUrl?: string | null,
    email?: string | null,
    phone?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVolunteerMutationVariables = {
  input: CreateVolunteerInput,
  condition?: ModelVolunteerConditionInput | null,
};

export type CreateVolunteerMutation = {
  createVolunteer?:  {
    __typename: "Volunteer",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    phone?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    status?: VolunteerStatus | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVolunteerMutationVariables = {
  input: UpdateVolunteerInput,
  condition?: ModelVolunteerConditionInput | null,
};

export type UpdateVolunteerMutation = {
  updateVolunteer?:  {
    __typename: "Volunteer",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    phone?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    status?: VolunteerStatus | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVolunteerMutationVariables = {
  input: DeleteVolunteerInput,
  condition?: ModelVolunteerConditionInput | null,
};

export type DeleteVolunteerMutation = {
  deleteVolunteer?:  {
    __typename: "Volunteer",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    phone?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    status?: VolunteerStatus | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateShiftMutationVariables = {
  input: CreateShiftInput,
  condition?: ModelShiftConditionInput | null,
};

export type CreateShiftMutation = {
  createShift?:  {
    __typename: "Shift",
    id: string,
    userId?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: ShiftStatus | null,
    managerGroup?: string | null,
    volunteerId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        userId?: string | null,
        organizationId?: string | null,
        volunteerId?: string | null,
        categoryId?: string | null,
        shiftId?: string | null,
        donationTime?: string | null,
        description?: string | null,
        estimatedValue?: number | null,
        amount: number,
        deletedAt?: string | null,
        donorName?: string | null,
        donorEmail?: string | null,
        donorPhone?: string | null,
        donorNeedsReceipt?: boolean | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateShiftMutationVariables = {
  input: UpdateShiftInput,
  condition?: ModelShiftConditionInput | null,
};

export type UpdateShiftMutation = {
  updateShift?:  {
    __typename: "Shift",
    id: string,
    userId?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: ShiftStatus | null,
    managerGroup?: string | null,
    volunteerId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        userId?: string | null,
        organizationId?: string | null,
        volunteerId?: string | null,
        categoryId?: string | null,
        shiftId?: string | null,
        donationTime?: string | null,
        description?: string | null,
        estimatedValue?: number | null,
        amount: number,
        deletedAt?: string | null,
        donorName?: string | null,
        donorEmail?: string | null,
        donorPhone?: string | null,
        donorNeedsReceipt?: boolean | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteShiftMutationVariables = {
  input: DeleteShiftInput,
  condition?: ModelShiftConditionInput | null,
};

export type DeleteShiftMutation = {
  deleteShift?:  {
    __typename: "Shift",
    id: string,
    userId?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: ShiftStatus | null,
    managerGroup?: string | null,
    volunteerId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        userId?: string | null,
        organizationId?: string | null,
        volunteerId?: string | null,
        categoryId?: string | null,
        shiftId?: string | null,
        donationTime?: string | null,
        description?: string | null,
        estimatedValue?: number | null,
        amount: number,
        deletedAt?: string | null,
        donorName?: string | null,
        donorEmail?: string | null,
        donorPhone?: string | null,
        donorNeedsReceipt?: boolean | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrganizationManagerMutationVariables = {
  input: CreateOrganizationManagerInput,
  condition?: ModelOrganizationManagerConditionInput | null,
};

export type CreateOrganizationManagerMutation = {
  createOrganizationManager?:  {
    __typename: "OrganizationManager",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ManagerStatus | null,
    managerGroup?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrganizationManagerMutationVariables = {
  input: UpdateOrganizationManagerInput,
  condition?: ModelOrganizationManagerConditionInput | null,
};

export type UpdateOrganizationManagerMutation = {
  updateOrganizationManager?:  {
    __typename: "OrganizationManager",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ManagerStatus | null,
    managerGroup?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganizationManagerMutationVariables = {
  input: DeleteOrganizationManagerInput,
  condition?: ModelOrganizationManagerConditionInput | null,
};

export type DeleteOrganizationManagerMutation = {
  deleteOrganizationManager?:  {
    __typename: "OrganizationManager",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ManagerStatus | null,
    managerGroup?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    description?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    logo?: string | null,
    hasCustomDonation?: boolean | null,
    managerGroup?: string | null,
    status?: OrganizationStatus | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    description?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    logo?: string | null,
    hasCustomDonation?: boolean | null,
    managerGroup?: string | null,
    status?: OrganizationStatus | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    description?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    logo?: string | null,
    hasCustomDonation?: boolean | null,
    managerGroup?: string | null,
    status?: OrganizationStatus | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    userId?: string | null,
    name: string,
    parentCategoryId?: string | null,
    parentCategory?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    managerGroup?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    userId?: string | null,
    name: string,
    parentCategoryId?: string | null,
    parentCategory?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    managerGroup?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    userId?: string | null,
    name: string,
    parentCategoryId?: string | null,
    parentCategory?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    managerGroup?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDonaterInfoMutationVariables = {
  input: CreateDonaterInfoInput,
  condition?: ModelDonaterInfoConditionInput | null,
};

export type CreateDonaterInfoMutation = {
  createDonaterInfo?:  {
    __typename: "DonaterInfo",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    wantsReceipt?: boolean | null,
    donationId?: string | null,
    donation?:  {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDonaterInfoMutationVariables = {
  input: UpdateDonaterInfoInput,
  condition?: ModelDonaterInfoConditionInput | null,
};

export type UpdateDonaterInfoMutation = {
  updateDonaterInfo?:  {
    __typename: "DonaterInfo",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    wantsReceipt?: boolean | null,
    donationId?: string | null,
    donation?:  {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDonaterInfoMutationVariables = {
  input: DeleteDonaterInfoInput,
  condition?: ModelDonaterInfoConditionInput | null,
};

export type DeleteDonaterInfoMutation = {
  deleteDonaterInfo?:  {
    __typename: "DonaterInfo",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    wantsReceipt?: boolean | null,
    donationId?: string | null,
    donation?:  {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDonationMutationVariables = {
  input: CreateDonationInput,
  condition?: ModelDonationConditionInput | null,
};

export type CreateDonationMutation = {
  createDonation?:  {
    __typename: "Donation",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    volunteerId?: string | null,
    categoryId?: string | null,
    shiftId?: string | null,
    donationTime?: string | null,
    description?: string | null,
    estimatedValue?: number | null,
    amount: number,
    deletedAt?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    shift?:  {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donorName?: string | null,
    donorEmail?: string | null,
    donorPhone?: string | null,
    donorNeedsReceipt?: boolean | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDonationMutationVariables = {
  input: UpdateDonationInput,
  condition?: ModelDonationConditionInput | null,
};

export type UpdateDonationMutation = {
  updateDonation?:  {
    __typename: "Donation",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    volunteerId?: string | null,
    categoryId?: string | null,
    shiftId?: string | null,
    donationTime?: string | null,
    description?: string | null,
    estimatedValue?: number | null,
    amount: number,
    deletedAt?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    shift?:  {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donorName?: string | null,
    donorEmail?: string | null,
    donorPhone?: string | null,
    donorNeedsReceipt?: boolean | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDonationMutationVariables = {
  input: DeleteDonationInput,
  condition?: ModelDonationConditionInput | null,
};

export type DeleteDonationMutation = {
  deleteDonation?:  {
    __typename: "Donation",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    volunteerId?: string | null,
    categoryId?: string | null,
    shiftId?: string | null,
    donationTime?: string | null,
    description?: string | null,
    estimatedValue?: number | null,
    amount: number,
    deletedAt?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    shift?:  {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donorName?: string | null,
    donorEmail?: string | null,
    donorPhone?: string | null,
    donorNeedsReceipt?: boolean | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePotentialUserMutationVariables = {
  input: CreatePotentialUserInput,
  condition?: ModelPotentialUserConditionInput | null,
};

export type CreatePotentialUserMutation = {
  createPotentialUser?:  {
    __typename: "PotentialUser",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePotentialUserMutationVariables = {
  input: UpdatePotentialUserInput,
  condition?: ModelPotentialUserConditionInput | null,
};

export type UpdatePotentialUserMutation = {
  updatePotentialUser?:  {
    __typename: "PotentialUser",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePotentialUserMutationVariables = {
  input: DeletePotentialUserInput,
  condition?: ModelPotentialUserConditionInput | null,
};

export type DeletePotentialUserMutation = {
  deletePotentialUser?:  {
    __typename: "PotentialUser",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVolunteerRequestMutationVariables = {
  input: CreateVolunteerRequestInput,
  condition?: ModelVolunteerRequestConditionInput | null,
};

export type CreateVolunteerRequestMutation = {
  createVolunteerRequest?:  {
    __typename: "VolunteerRequest",
    id: string,
    message?: string | null,
    status?: VolunteerRequestStatus | null,
    managerGroup?: string | null,
    userId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVolunteerRequestMutationVariables = {
  input: UpdateVolunteerRequestInput,
  condition?: ModelVolunteerRequestConditionInput | null,
};

export type UpdateVolunteerRequestMutation = {
  updateVolunteerRequest?:  {
    __typename: "VolunteerRequest",
    id: string,
    message?: string | null,
    status?: VolunteerRequestStatus | null,
    managerGroup?: string | null,
    userId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVolunteerRequestMutationVariables = {
  input: DeleteVolunteerRequestInput,
  condition?: ModelVolunteerRequestConditionInput | null,
};

export type DeleteVolunteerRequestMutation = {
  deleteVolunteerRequest?:  {
    __typename: "VolunteerRequest",
    id: string,
    message?: string | null,
    status?: VolunteerRequestStatus | null,
    managerGroup?: string | null,
    userId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    profileImageUrl?: string | null,
    email?: string | null,
    phone?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVolunteerQueryVariables = {
  id: string,
};

export type GetVolunteerQuery = {
  getVolunteer?:  {
    __typename: "Volunteer",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    phone?: string | null,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    status?: VolunteerStatus | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVolunteersQueryVariables = {
  filter?: ModelVolunteerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVolunteersQuery = {
  listVolunteers?:  {
    __typename: "ModelVolunteerConnection",
    items:  Array< {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteersByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteersByUserIdQuery = {
  volunteersByUserId?:  {
    __typename: "ModelVolunteerConnection",
    items:  Array< {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteersByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteersByOrganizationIdQuery = {
  volunteersByOrganizationId?:  {
    __typename: "ModelVolunteerConnection",
    items:  Array< {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteersByOrganizationAndUserIdQueryVariables = {
  organizationId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteersByOrganizationAndUserIdQuery = {
  volunteersByOrganizationAndUserId?:  {
    __typename: "ModelVolunteerConnection",
    items:  Array< {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteersByOrganizationAndStatusQueryVariables = {
  organizationId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteersByOrganizationAndStatusQuery = {
  volunteersByOrganizationAndStatus?:  {
    __typename: "ModelVolunteerConnection",
    items:  Array< {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteersByStatusQueryVariables = {
  status: VolunteerStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteersByStatusQuery = {
  volunteersByStatus?:  {
    __typename: "ModelVolunteerConnection",
    items:  Array< {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShiftQueryVariables = {
  id: string,
};

export type GetShiftQuery = {
  getShift?:  {
    __typename: "Shift",
    id: string,
    userId?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    status?: ShiftStatus | null,
    managerGroup?: string | null,
    volunteerId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donations?:  {
      __typename: "ModelDonationConnection",
      items:  Array< {
        __typename: "Donation",
        id: string,
        userId?: string | null,
        organizationId?: string | null,
        volunteerId?: string | null,
        categoryId?: string | null,
        shiftId?: string | null,
        donationTime?: string | null,
        description?: string | null,
        estimatedValue?: number | null,
        amount: number,
        deletedAt?: string | null,
        donorName?: string | null,
        donorEmail?: string | null,
        donorPhone?: string | null,
        donorNeedsReceipt?: boolean | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListShiftsQueryVariables = {
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShiftsQuery = {
  listShifts?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShiftsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShiftsByUserQuery = {
  shiftsByUser?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShiftsByStatusQueryVariables = {
  status: ShiftStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShiftsByStatusQuery = {
  shiftsByStatus?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShiftsByVolunteerIdQueryVariables = {
  volunteerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShiftsByVolunteerIdQuery = {
  shiftsByVolunteerId?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShiftsByVolunteerIdAndStatusQueryVariables = {
  volunteerId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShiftsByVolunteerIdAndStatusQuery = {
  shiftsByVolunteerIdAndStatus?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShiftsByVolunteerIdAndStartQueryVariables = {
  volunteerId: string,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShiftsByVolunteerIdAndStartQuery = {
  shiftsByVolunteerIdAndStart?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShiftsByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShiftsByOrganizationIdQuery = {
  shiftsByOrganizationId?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShiftsByOrganizationIdAndStatusQueryVariables = {
  organizationId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShiftsByOrganizationIdAndStatusQuery = {
  shiftsByOrganizationIdAndStatus?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShiftsByOrganizationIdAndStartTimeAndEndTimeQueryVariables = {
  organizationId: string,
  startTimeEndTime?: ModelShiftByOrganizationIdAndStartAndEndCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShiftsByOrganizationIdAndStartTimeAndEndTimeQuery = {
  shiftsByOrganizationIdAndStartTimeAndEndTime?:  {
    __typename: "ModelShiftConnection",
    items:  Array< {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationManagerQueryVariables = {
  id: string,
};

export type GetOrganizationManagerQuery = {
  getOrganizationManager?:  {
    __typename: "OrganizationManager",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ManagerStatus | null,
    managerGroup?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrganizationManagersQueryVariables = {
  filter?: ModelOrganizationManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationManagersQuery = {
  listOrganizationManagers?:  {
    __typename: "ModelOrganizationManagerConnection",
    items:  Array< {
      __typename: "OrganizationManager",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ManagerStatus | null,
      managerGroup?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ManagersByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ManagersByUserQuery = {
  managersByUser?:  {
    __typename: "ModelOrganizationManagerConnection",
    items:  Array< {
      __typename: "OrganizationManager",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ManagerStatus | null,
      managerGroup?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ManagersByOrganizationQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ManagersByOrganizationQuery = {
  managersByOrganization?:  {
    __typename: "ModelOrganizationManagerConnection",
    items:  Array< {
      __typename: "OrganizationManager",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ManagerStatus | null,
      managerGroup?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ManagersByOrganizationAndUserIdQueryVariables = {
  organizationId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ManagersByOrganizationAndUserIdQuery = {
  managersByOrganizationAndUserId?:  {
    __typename: "ModelOrganizationManagerConnection",
    items:  Array< {
      __typename: "OrganizationManager",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ManagerStatus | null,
      managerGroup?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ManagersByStatusQueryVariables = {
  status: ManagerStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ManagersByStatusQuery = {
  managersByStatus?:  {
    __typename: "ModelOrganizationManagerConnection",
    items:  Array< {
      __typename: "OrganizationManager",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ManagerStatus | null,
      managerGroup?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    description?: string | null,
    street1?: string | null,
    street2?: string | null,
    city?: string | null,
    state?: string | null,
    zip?: string | null,
    phone?: string | null,
    email?: string | null,
    website?: string | null,
    logo?: string | null,
    hasCustomDonation?: boolean | null,
    managerGroup?: string | null,
    status?: OrganizationStatus | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationsByStatusQueryVariables = {
  status: OrganizationStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationsByStatusQuery = {
  organizationsByStatus?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationsByOwnerQueryVariables = {
  owner: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationsByOwnerQuery = {
  organizationsByOwner?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    userId?: string | null,
    name: string,
    parentCategoryId?: string | null,
    parentCategory?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    managerGroup?: string | null,
    deletedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByUserQuery = {
  categoriesByUser?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByUserAndDeletedAtQueryVariables = {
  userId: string,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByUserAndDeletedAtQuery = {
  categoriesByUserAndDeletedAt?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByParentCategoryQueryVariables = {
  parentCategoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByParentCategoryQuery = {
  categoriesByParentCategory?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByParentCategoryAndDeletedAtQueryVariables = {
  parentCategoryId: string,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByParentCategoryAndDeletedAtQuery = {
  categoriesByParentCategoryAndDeletedAt?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByOrganizationQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByOrganizationQuery = {
  categoriesByOrganization?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByOrganizationAndDeletedAtQueryVariables = {
  organizationId: string,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByOrganizationAndDeletedAtQuery = {
  categoriesByOrganizationAndDeletedAt?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDonaterInfoQueryVariables = {
  id: string,
};

export type GetDonaterInfoQuery = {
  getDonaterInfo?:  {
    __typename: "DonaterInfo",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    wantsReceipt?: boolean | null,
    donationId?: string | null,
    donation?:  {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDonaterInfosQueryVariables = {
  filter?: ModelDonaterInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDonaterInfosQuery = {
  listDonaterInfos?:  {
    __typename: "ModelDonaterInfoConnection",
    items:  Array< {
      __typename: "DonaterInfo",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name?: string | null,
      email?: string | null,
      phone?: string | null,
      wantsReceipt?: boolean | null,
      donationId?: string | null,
      donation?:  {
        __typename: "Donation",
        id: string,
        userId?: string | null,
        organizationId?: string | null,
        volunteerId?: string | null,
        categoryId?: string | null,
        shiftId?: string | null,
        donationTime?: string | null,
        description?: string | null,
        estimatedValue?: number | null,
        amount: number,
        deletedAt?: string | null,
        donorName?: string | null,
        donorEmail?: string | null,
        donorPhone?: string | null,
        donorNeedsReceipt?: boolean | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonaterInfosByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonaterInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonaterInfosByUserQuery = {
  donaterInfosByUser?:  {
    __typename: "ModelDonaterInfoConnection",
    items:  Array< {
      __typename: "DonaterInfo",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name?: string | null,
      email?: string | null,
      phone?: string | null,
      wantsReceipt?: boolean | null,
      donationId?: string | null,
      donation?:  {
        __typename: "Donation",
        id: string,
        userId?: string | null,
        organizationId?: string | null,
        volunteerId?: string | null,
        categoryId?: string | null,
        shiftId?: string | null,
        donationTime?: string | null,
        description?: string | null,
        estimatedValue?: number | null,
        amount: number,
        deletedAt?: string | null,
        donorName?: string | null,
        donorEmail?: string | null,
        donorPhone?: string | null,
        donorNeedsReceipt?: boolean | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonaterInfosByOrganizationQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonaterInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonaterInfosByOrganizationQuery = {
  donaterInfosByOrganization?:  {
    __typename: "ModelDonaterInfoConnection",
    items:  Array< {
      __typename: "DonaterInfo",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name?: string | null,
      email?: string | null,
      phone?: string | null,
      wantsReceipt?: boolean | null,
      donationId?: string | null,
      donation?:  {
        __typename: "Donation",
        id: string,
        userId?: string | null,
        organizationId?: string | null,
        volunteerId?: string | null,
        categoryId?: string | null,
        shiftId?: string | null,
        donationTime?: string | null,
        description?: string | null,
        estimatedValue?: number | null,
        amount: number,
        deletedAt?: string | null,
        donorName?: string | null,
        donorEmail?: string | null,
        donorPhone?: string | null,
        donorNeedsReceipt?: boolean | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonaterInfosByDonationQueryVariables = {
  donationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonaterInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonaterInfosByDonationQuery = {
  donaterInfosByDonation?:  {
    __typename: "ModelDonaterInfoConnection",
    items:  Array< {
      __typename: "DonaterInfo",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      name?: string | null,
      email?: string | null,
      phone?: string | null,
      wantsReceipt?: boolean | null,
      donationId?: string | null,
      donation?:  {
        __typename: "Donation",
        id: string,
        userId?: string | null,
        organizationId?: string | null,
        volunteerId?: string | null,
        categoryId?: string | null,
        shiftId?: string | null,
        donationTime?: string | null,
        description?: string | null,
        estimatedValue?: number | null,
        amount: number,
        deletedAt?: string | null,
        donorName?: string | null,
        donorEmail?: string | null,
        donorPhone?: string | null,
        donorNeedsReceipt?: boolean | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDonationQueryVariables = {
  id: string,
};

export type GetDonationQuery = {
  getDonation?:  {
    __typename: "Donation",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    volunteerId?: string | null,
    categoryId?: string | null,
    shiftId?: string | null,
    donationTime?: string | null,
    description?: string | null,
    estimatedValue?: number | null,
    amount: number,
    deletedAt?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    shift?:  {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donorName?: string | null,
    donorEmail?: string | null,
    donorPhone?: string | null,
    donorNeedsReceipt?: boolean | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDonationsQueryVariables = {
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDonationsQuery = {
  listDonations?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByUserQuery = {
  donationsByUser?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByUserAndDateQueryVariables = {
  userId: string,
  donationTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByUserAndDateQuery = {
  donationsByUserAndDate?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByUserAndDeletedAtQueryVariables = {
  userId: string,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByUserAndDeletedAtQuery = {
  donationsByUserAndDeletedAt?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByOrganizationQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByOrganizationQuery = {
  donationsByOrganization?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByOrganizationAndDateQueryVariables = {
  organizationId: string,
  donationTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByOrganizationAndDateQuery = {
  donationsByOrganizationAndDate?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByOrganizationAndDeletedAtQueryVariables = {
  organizationId: string,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByOrganizationAndDeletedAtQuery = {
  donationsByOrganizationAndDeletedAt?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByVolunteerQueryVariables = {
  volunteerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByVolunteerQuery = {
  donationsByVolunteer?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByVolunteerAndDateQueryVariables = {
  volunteerId: string,
  donationTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByVolunteerAndDateQuery = {
  donationsByVolunteerAndDate?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByVolunteerAndDeletedAtQueryVariables = {
  volunteerId: string,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByVolunteerAndDeletedAtQuery = {
  donationsByVolunteerAndDeletedAt?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByCategoryQueryVariables = {
  categoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByCategoryQuery = {
  donationsByCategory?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByCategoryAndDateQueryVariables = {
  categoryId: string,
  donationTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByCategoryAndDateQuery = {
  donationsByCategoryAndDate?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByCategoryAndDeletedAtQueryVariables = {
  categoryId: string,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByCategoryAndDeletedAtQuery = {
  donationsByCategoryAndDeletedAt?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByShiftQueryVariables = {
  shiftId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByShiftQuery = {
  donationsByShift?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByShiftAndDateQueryVariables = {
  shiftId: string,
  donationTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByShiftAndDateQuery = {
  donationsByShiftAndDate?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DonationsByShiftAndDeletedAtQueryVariables = {
  shiftId: string,
  deletedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDonationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DonationsByShiftAndDeletedAtQuery = {
  donationsByShiftAndDeletedAt?:  {
    __typename: "ModelDonationConnection",
    items:  Array< {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPotentialUserQueryVariables = {
  id: string,
};

export type GetPotentialUserQuery = {
  getPotentialUser?:  {
    __typename: "PotentialUser",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPotentialUsersQueryVariables = {
  filter?: ModelPotentialUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPotentialUsersQuery = {
  listPotentialUsers?:  {
    __typename: "ModelPotentialUserConnection",
    items:  Array< {
      __typename: "PotentialUser",
      id: string,
      email: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVolunteerRequestQueryVariables = {
  id: string,
};

export type GetVolunteerRequestQuery = {
  getVolunteerRequest?:  {
    __typename: "VolunteerRequest",
    id: string,
    message?: string | null,
    status?: VolunteerRequestStatus | null,
    managerGroup?: string | null,
    userId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVolunteerRequestsQueryVariables = {
  filter?: ModelVolunteerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVolunteerRequestsQuery = {
  listVolunteerRequests?:  {
    __typename: "ModelVolunteerRequestConnection",
    items:  Array< {
      __typename: "VolunteerRequest",
      id: string,
      message?: string | null,
      status?: VolunteerRequestStatus | null,
      managerGroup?: string | null,
      userId: string,
      organizationId: string,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteerRequestsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteerRequestsByUserQuery = {
  volunteerRequestsByUser?:  {
    __typename: "ModelVolunteerRequestConnection",
    items:  Array< {
      __typename: "VolunteerRequest",
      id: string,
      message?: string | null,
      status?: VolunteerRequestStatus | null,
      managerGroup?: string | null,
      userId: string,
      organizationId: string,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteerRequestsByUserAndStatusQueryVariables = {
  userId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteerRequestsByUserAndStatusQuery = {
  volunteerRequestsByUserAndStatus?:  {
    __typename: "ModelVolunteerRequestConnection",
    items:  Array< {
      __typename: "VolunteerRequest",
      id: string,
      message?: string | null,
      status?: VolunteerRequestStatus | null,
      managerGroup?: string | null,
      userId: string,
      organizationId: string,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteerRequestsByUserAndOrganizationQueryVariables = {
  userId: string,
  organizationId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteerRequestsByUserAndOrganizationQuery = {
  volunteerRequestsByUserAndOrganization?:  {
    __typename: "ModelVolunteerRequestConnection",
    items:  Array< {
      __typename: "VolunteerRequest",
      id: string,
      message?: string | null,
      status?: VolunteerRequestStatus | null,
      managerGroup?: string | null,
      userId: string,
      organizationId: string,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteerRequestsByOrganizationQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteerRequestsByOrganizationQuery = {
  volunteerRequestsByOrganization?:  {
    __typename: "ModelVolunteerRequestConnection",
    items:  Array< {
      __typename: "VolunteerRequest",
      id: string,
      message?: string | null,
      status?: VolunteerRequestStatus | null,
      managerGroup?: string | null,
      userId: string,
      organizationId: string,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteerRequestsByOrganizationAndStatusQueryVariables = {
  organizationId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteerRequestsByOrganizationAndStatusQuery = {
  volunteerRequestsByOrganizationAndStatus?:  {
    __typename: "ModelVolunteerRequestConnection",
    items:  Array< {
      __typename: "VolunteerRequest",
      id: string,
      message?: string | null,
      status?: VolunteerRequestStatus | null,
      managerGroup?: string | null,
      userId: string,
      organizationId: string,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolunteerRequestsByOrganizationAndUserQueryVariables = {
  organizationId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVolunteerRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolunteerRequestsByOrganizationAndUserQuery = {
  volunteerRequestsByOrganizationAndUser?:  {
    __typename: "ModelVolunteerRequestConnection",
    items:  Array< {
      __typename: "VolunteerRequest",
      id: string,
      message?: string | null,
      status?: VolunteerRequestStatus | null,
      managerGroup?: string | null,
      userId: string,
      organizationId: string,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  query?: string | null,
  filters?: string | null,
  searchableAttrs?: Array< string | null > | null,
  typoTolerance?: boolean | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "FindableUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchOrganizationsQueryVariables = {
  query?: string | null,
  filters?: string | null,
  searchableAttrs?: Array< string | null > | null,
  typoTolerance?: boolean | null,
  aroundLatLng?: string | null,
};

export type SearchOrganizationsQuery = {
  searchOrganizations?:  {
    __typename: "FindableOrganizationConnection",
    items?:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type OnCreateDonaterInfoSubscriptionVariables = {
  filter?: ModelSubscriptionDonaterInfoFilterInput | null,
  userId?: string | null,
};

export type OnCreateDonaterInfoSubscription = {
  onCreateDonaterInfo?:  {
    __typename: "DonaterInfo",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    wantsReceipt?: boolean | null,
    donationId?: string | null,
    donation?:  {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDonaterInfoSubscriptionVariables = {
  filter?: ModelSubscriptionDonaterInfoFilterInput | null,
  userId?: string | null,
};

export type OnUpdateDonaterInfoSubscription = {
  onUpdateDonaterInfo?:  {
    __typename: "DonaterInfo",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    wantsReceipt?: boolean | null,
    donationId?: string | null,
    donation?:  {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDonaterInfoSubscriptionVariables = {
  filter?: ModelSubscriptionDonaterInfoFilterInput | null,
  userId?: string | null,
};

export type OnDeleteDonaterInfoSubscription = {
  onDeleteDonaterInfo?:  {
    __typename: "DonaterInfo",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    wantsReceipt?: boolean | null,
    donationId?: string | null,
    donation?:  {
      __typename: "Donation",
      id: string,
      userId?: string | null,
      organizationId?: string | null,
      volunteerId?: string | null,
      categoryId?: string | null,
      shiftId?: string | null,
      donationTime?: string | null,
      description?: string | null,
      estimatedValue?: number | null,
      amount: number,
      deletedAt?: string | null,
      category?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      shift?:  {
        __typename: "Shift",
        id: string,
        userId?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        status?: ShiftStatus | null,
        managerGroup?: string | null,
        volunteerId?: string | null,
        organizationId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donorName?: string | null,
      donorEmail?: string | null,
      donorPhone?: string | null,
      donorNeedsReceipt?: boolean | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDonationSubscriptionVariables = {
  filter?: ModelSubscriptionDonationFilterInput | null,
  userId?: string | null,
};

export type OnCreateDonationSubscription = {
  onCreateDonation?:  {
    __typename: "Donation",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    volunteerId?: string | null,
    categoryId?: string | null,
    shiftId?: string | null,
    donationTime?: string | null,
    description?: string | null,
    estimatedValue?: number | null,
    amount: number,
    deletedAt?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    shift?:  {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donorName?: string | null,
    donorEmail?: string | null,
    donorPhone?: string | null,
    donorNeedsReceipt?: boolean | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDonationSubscriptionVariables = {
  filter?: ModelSubscriptionDonationFilterInput | null,
  userId?: string | null,
};

export type OnUpdateDonationSubscription = {
  onUpdateDonation?:  {
    __typename: "Donation",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    volunteerId?: string | null,
    categoryId?: string | null,
    shiftId?: string | null,
    donationTime?: string | null,
    description?: string | null,
    estimatedValue?: number | null,
    amount: number,
    deletedAt?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    shift?:  {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donorName?: string | null,
    donorEmail?: string | null,
    donorPhone?: string | null,
    donorNeedsReceipt?: boolean | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDonationSubscriptionVariables = {
  filter?: ModelSubscriptionDonationFilterInput | null,
  userId?: string | null,
};

export type OnDeleteDonationSubscription = {
  onDeleteDonation?:  {
    __typename: "Donation",
    id: string,
    userId?: string | null,
    organizationId?: string | null,
    volunteerId?: string | null,
    categoryId?: string | null,
    shiftId?: string | null,
    donationTime?: string | null,
    description?: string | null,
    estimatedValue?: number | null,
    amount: number,
    deletedAt?: string | null,
    category?:  {
      __typename: "Category",
      id: string,
      userId?: string | null,
      name: string,
      parentCategoryId?: string | null,
      parentCategory?:  {
        __typename: "Category",
        id: string,
        userId?: string | null,
        name: string,
        parentCategoryId?: string | null,
        organizationId?: string | null,
        managerGroup?: string | null,
        deletedAt?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      managerGroup?: string | null,
      deletedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    volunteer?:  {
      __typename: "Volunteer",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      phone?: string | null,
      userId?: string | null,
      organizationId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      status?: VolunteerStatus | null,
      managerGroup?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    shift?:  {
      __typename: "Shift",
      id: string,
      userId?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      status?: ShiftStatus | null,
      managerGroup?: string | null,
      volunteerId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        description?: string | null,
        street1?: string | null,
        street2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        phone?: string | null,
        email?: string | null,
        website?: string | null,
        logo?: string | null,
        hasCustomDonation?: boolean | null,
        managerGroup?: string | null,
        status?: OrganizationStatus | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        profileImageUrl?: string | null,
        email?: string | null,
        phone?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      volunteer?:  {
        __typename: "Volunteer",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        phone?: string | null,
        userId?: string | null,
        organizationId?: string | null,
        owner?: string | null,
        status?: VolunteerStatus | null,
        managerGroup?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      donations?:  {
        __typename: "ModelDonationConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    donorName?: string | null,
    donorEmail?: string | null,
    donorPhone?: string | null,
    donorNeedsReceipt?: boolean | null,
    managerGroup?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePotentialUserSubscriptionVariables = {
  filter?: ModelSubscriptionPotentialUserFilterInput | null,
};

export type OnCreatePotentialUserSubscription = {
  onCreatePotentialUser?:  {
    __typename: "PotentialUser",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePotentialUserSubscriptionVariables = {
  filter?: ModelSubscriptionPotentialUserFilterInput | null,
};

export type OnUpdatePotentialUserSubscription = {
  onUpdatePotentialUser?:  {
    __typename: "PotentialUser",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePotentialUserSubscriptionVariables = {
  filter?: ModelSubscriptionPotentialUserFilterInput | null,
};

export type OnDeletePotentialUserSubscription = {
  onDeletePotentialUser?:  {
    __typename: "PotentialUser",
    id: string,
    email: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVolunteerRequestSubscriptionVariables = {
  filter?: ModelSubscriptionVolunteerRequestFilterInput | null,
  userId?: string | null,
};

export type OnCreateVolunteerRequestSubscription = {
  onCreateVolunteerRequest?:  {
    __typename: "VolunteerRequest",
    id: string,
    message?: string | null,
    status?: VolunteerRequestStatus | null,
    managerGroup?: string | null,
    userId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVolunteerRequestSubscriptionVariables = {
  filter?: ModelSubscriptionVolunteerRequestFilterInput | null,
  userId?: string | null,
};

export type OnUpdateVolunteerRequestSubscription = {
  onUpdateVolunteerRequest?:  {
    __typename: "VolunteerRequest",
    id: string,
    message?: string | null,
    status?: VolunteerRequestStatus | null,
    managerGroup?: string | null,
    userId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVolunteerRequestSubscriptionVariables = {
  filter?: ModelSubscriptionVolunteerRequestFilterInput | null,
  userId?: string | null,
};

export type OnDeleteVolunteerRequestSubscription = {
  onDeleteVolunteerRequest?:  {
    __typename: "VolunteerRequest",
    id: string,
    message?: string | null,
    status?: VolunteerRequestStatus | null,
    managerGroup?: string | null,
    userId: string,
    organizationId: string,
    user?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      profileImageUrl?: string | null,
      email?: string | null,
      phone?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      description?: string | null,
      street1?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      phone?: string | null,
      email?: string | null,
      website?: string | null,
      logo?: string | null,
      hasCustomDonation?: boolean | null,
      managerGroup?: string | null,
      status?: OrganizationStatus | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
