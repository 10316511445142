import {
  differenceInHours,
  format,
  formatDistanceToNow,
  isTomorrow,
  isToday,
  isYesterday,
  differenceInSeconds,
} from "date-fns";
import formatISO from "date-fns/formatISO";

export const getTimeStamp = (): string => {
  return formatISO(new Date());
};

export const getTimeDisplay = (time: string): string => {
  if (isTomorrow(new Date(time))) {
    return `Tomorrow at ${format(new Date(time), "p")}`;
  } else if (differenceInHours(new Date(time), new Date()) > 24) {
    return format(new Date(time), "E, MMM do 'at' p 'EST'");
  } else {
    return `${formatDistanceToNow(new Date(time), {
      addSuffix: true,
    })} at ${format(new Date(time), "p")}`;
  }
};

export const ago = (date: string): string => {
  const timestamp = new Date(date);
  if (isToday(timestamp)) {
    return formatDistanceToNow(timestamp, { addSuffix: true });
  } else if (isYesterday(timestamp)) {
    return `Yesterday at ${format(timestamp, "p")}`;
  }

  return format(timestamp, "MMM do 'at' p 'EST'");
};

export const shortDate = (date: string): string => {
  return format(new Date(date), "yyyy-MM-dd p");
};

export const getDuration = ({
  startTime,
  endTime: _endTime,
}: {
  startTime?: string | null;
  endTime?: string | null;
}): string | null => {
  let endTime = _endTime ? _endTime : new Date().toISOString();
  if (!startTime) {
    return null;
  }
  let diffHours = null,
    diffMinutes = null,
    diffDays = null,
    diffSeconds = null;
  let totalTime = null;
  if (startTime && endTime) {
    diffSeconds = differenceInSeconds(new Date(endTime), new Date(startTime));
    diffMinutes = Math.floor(diffSeconds / 60);
    diffHours = Math.floor(diffMinutes / 60);
    diffDays = Math.floor(diffHours / 24);

    // fix for overflows
    diffSeconds = diffSeconds % 60;
    diffMinutes = diffMinutes % 60;
    diffHours = diffHours % 24;
    const diffDaysDisplay = diffDays > 0 ? `${diffDays}d` : null;
    const diffHoursDisplay = diffHours > 0 ? `${diffHours}h` : null;
    const diffMinutesDisplay = diffMinutes > 0 ? `${diffMinutes}m` : null;

    if (diffMinutes === 0 && diffHours === 0 && diffDays === 0) {
      totalTime = `${diffSeconds} seconds`;
    } else {
      totalTime = [diffDaysDisplay, diffHoursDisplay, diffMinutesDisplay]
        .filter(Boolean)
        .join(":");
    }
  }
  return totalTime;
};
