import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  createVolunteer,
  getVolunteerRequestsForOrg,
  getVolunteersForOrg,
  saveVolunteer,
  updateVolunteerRequest,
} from "../backend";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Volunteer, VolunteerRequest, VolunteerRequestStatus } from "src/API";
import { LoadingButton } from "@mui/lab";

const useVolunteers = (orgId?: string | null) => {
  return useQuery(
    [orgId, "volunteers"],
    async () => {
      if (!orgId) {
        return [];
      }
      const volunteers = await getVolunteersForOrg({ id: orgId });

      return volunteers;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const useVolunteerRequests = (orgId?: string | null) => {
  return useQuery(
    [orgId, "volunteer-requests"],
    async () => {
      if (!orgId) {
        return [];
      }
      const volunteerRequests = await getVolunteerRequestsForOrg({ id: orgId });
      return volunteerRequests;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const Volunteers = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab");
  const [activeTab, setActiveTab] = useState<"current" | "pending">(
    _activeTab === "pending" ? "pending" : "current",
  );
  const {
    data: volunteers,
    isFetching: isFetchingVolunteers,
    isLoading: isLoadingVolunteers,
    refetch: refetchVolunteers,
  } = useVolunteers(orgId);

  const {
    data: volunteerRequests,
    isFetching: isFetchingRequests,
    isLoading: isLoadingVolunteerRequests,
    refetch: refetchVolunteerRequests,
  } = useVolunteerRequests(orgId);

  const isFetching = isFetchingRequests || isFetchingVolunteers;

  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);

  const [confirmReject, setConfirmReject] = useState<VolunteerRequest | null>(
    null,
  );
  const [confirmAccept, setConfirmAccept] = useState<VolunteerRequest | null>(
    null,
  );

  const [editingVolunteer, setEditingVolunteer] = useState<null | Volunteer>(
    null,
  );
  const [volunteerToDeactivate, setVolunteerToDeactivate] =
    useState<Volunteer | null>(null);

  const [newVolunteerValues, setNewVolunteerValues] = useState<{
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    street1?: string;
  }>({});

  const [isSavingVolunteer, setIsSavingVolunteer] = useState(false);

  useEffect(() => {
    setSearchParams({ tab: "volunteers", activeTab });
  }, [activeTab, setSearchParams]);

  useEffect(() => {
    if (editingVolunteer) {
      setNewVolunteerValues({
        firstName: editingVolunteer.firstName,
        lastName: editingVolunteer.lastName,
        email: editingVolunteer.email,
      });
    }
  }, [editingVolunteer]);

  const handleConfirmAccept = async () => {
    if (!confirmAccept || !orgId) {
      return;
    }
    try {
      const userId = confirmAccept.userId;
      const result = await updateVolunteerRequest({
        id: confirmAccept.id,
        status: VolunteerRequestStatus.APPROVED,
      });
      const newVolunteer = await createVolunteer({
        organizationId: orgId,
        userId,
      });
      if (result && newVolunteer) {
        setConfirmAccept(null);
        refetchVolunteerRequests();
      }
    } catch (e) {
      console.log("[ERROR] error confirming", e);
    }
  };
  const handleConfirmReject = async () => {
    if (!confirmReject) {
      return;
    }
    try {
      const result = await updateVolunteerRequest({
        id: confirmReject.id,
        status: VolunteerRequestStatus.REJECTED,
      });
      if (result) {
        setConfirmReject(null);
        refetchVolunteerRequests();
      }
    } catch (e) {
      console.log("[ERROR] error rejecting", e);
    }
  };

  const handleSaveVolunteer = async () => {
    setIsSavingVolunteer(true);

    if (
      !newVolunteerValues ||
      !editingVolunteer ||
      !newVolunteerValues.firstName ||
      !newVolunteerValues.lastName
    ) {
      return;
    }
    const isSaved = await saveVolunteer({
      id: editingVolunteer?.id,
      firstName: newVolunteerValues.firstName,
      lastName: newVolunteerValues.lastName,
      email: newVolunteerValues.email,
    });
    if (isSaved) {
      setEditingVolunteer(null);
      refetchVolunteers();
      setTimeout(() => {
        setIsSavingVolunteer(false);
      }, 100);
    }
  };

  const handleDeactivateVolunteer = async (vol: Volunteer | null) => {
    try {
      setIsDeactivating(true);

      await new Promise((res) => {
        setTimeout(res, 2000);
      });
      setVolunteerToDeactivate(null);
      refetchVolunteers();
    } catch (e) {
    } finally {
      setIsDeactivating(false);
    }
  };

  const handlePromptDeactivate = (vol: Volunteer | null) => {
    setVolunteerToDeactivate(vol);
    setEditingVolunteer(null);
  };

  const renderCurrentVolunteers = () => {
    if (isLoadingVolunteers) {
      return <CircularProgress />;
    }
    return (
      <Grid container spacing={3}>
        {volunteers?.map((volunteer) => {
          return (
            <Grid key={`current-volunteer-${volunteer.id}`} item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography>
                    {volunteer.firstName} {volunteer.lastName}
                  </Typography>
                  {volunteer.email && (
                    <Typography variant="caption">
                      ({volunteer.email})
                    </Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Button onClick={() => setEditingVolunteer(volunteer)}>
                    Edit
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderVolunteerRequests = () => {
    if (isLoadingVolunteerRequests) {
      return <CircularProgress />;
    }
    return (
      <Grid container spacing={3}>
        {volunteerRequests?.length === 0 && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography>No pending requests</Typography>
          </Grid>
        )}
        {volunteerRequests?.map((request) => {
          if (!request || !request.user) {
            return null;
          }
          return (
            <Grid key={`volunteer-request-${request.id}`} item xs={12} md={6}>
              <Card>
                <CardContent>
                  {request.user.firstName} {request.user.lastName}
                  {request.message && (
                    <Typography
                      sx={{
                        p: 2,
                        border: "1px #ddd solid",
                        borderRadius: "5px",
                        fontStyle: "italic",
                      }}>
                      {request.message}
                    </Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Button onClick={() => setConfirmAccept(request)}>
                    Accept
                  </Button>
                  <Button
                    color="error"
                    onClick={() => setConfirmReject(request)}>
                    Reject
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "current":
        return renderCurrentVolunteers();
      case "pending":
        return renderVolunteerRequests();
      default:
        return null;
    }
  };
  return (
    <Container>
      <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
        <Tab
          label={`Current ${
            volunteers?.length ? `(${volunteers.length})` : ""
          }`}
          value={"current"}
        />
        <Tab
          label={`Pending ${
            volunteerRequests?.length ? `(${volunteerRequests.length})` : ""
          }`}
          value={"pending"}
        />
      </Tabs>
      <Box sx={{ p: 1, position: "relative" }}>
        {isFetching && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, .75)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1299,
            }}>
            <Paper sx={{ p: 3 }}>
              <CircularProgress />
            </Paper>
          </Box>
        )}
        {renderActiveTab()}
      </Box>
      <Dialog
        open={confirmAccept !== null}
        onClose={() => setConfirmAccept(null)}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to accept this volunteer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmAccept}>Accept</Button>
          <Button onClick={() => setConfirmAccept(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmReject !== null}
        onClose={() => setConfirmReject(null)}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reject this volunteer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleConfirmReject}>
            Reject
          </Button>
          <Button onClick={() => setConfirmReject(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(editingVolunteer)}
        onClose={() => setEditingVolunteer(null)}>
        <DialogContent>
          <TextField
            fullWidth
            value={newVolunteerValues.firstName || ""}
            placeholder="First Name"
            onChange={(e) => {
              setNewVolunteerValues({
                ...newVolunteerValues,
                firstName: e.target.value,
              });
            }}
          />
          <TextField
            fullWidth
            placeholder="Last Name"
            onChange={(e) => {
              setNewVolunteerValues({
                ...newVolunteerValues,
                lastName: e.target.value,
              });
            }}
            value={newVolunteerValues.lastName || ""}
            sx={{ mt: 1 }}
          />
          <TextField
            fullWidth
            placeholder="Email"
            onChange={(e) => {
              setNewVolunteerValues({
                ...newVolunteerValues,
                email: e.target.value,
              });
            }}
            value={newVolunteerValues.email || ""}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isSavingVolunteer}
            variant="contained"
            onClick={() => {
              handleSaveVolunteer();
            }}>
            Save
          </LoadingButton>
          <Button onClick={() => setEditingVolunteer(null)}>Cancel</Button>
          <Button
            onClick={() => handlePromptDeactivate(editingVolunteer)}
            color="error">
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={Boolean(volunteerToDeactivate)}>
        <DialogTitle>Deactivate Volunteer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate this volunteer? This will
            restrict the volunteer from creating shifts or donations.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isDeactivating}
            onClick={() => handleDeactivateVolunteer(editingVolunteer)}
            color="error"
            variant="contained">
            Yes, Deactivate
          </LoadingButton>
          <Button onClick={() => setVolunteerToDeactivate(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Volunteers;
