import { Alert, Container } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Routes, Route } from "react-router-dom";
import Navigation from "./Navigation";

import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { getProfileForUser } from "./Profile/backend";
import Onboarding from "./Onboarding";
import { useDispatch } from "react-redux";
import { setUser } from "../stores/UserStore";

import AppRoutes from "src/routes";

const queryClient = new QueryClient();

const MainApp = () => {
  const [error, setError] = useState<string | null>(null);
  const [needsAuth, setNeedsAuth] = useState<boolean>(false);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const loadProfile = async () => {
      let user;
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (e) {
        console.log("[ERROR] error getting authed user", e);
        setNeedsAuth(true);
        return;
      }
      try {
        if (user) {
          const profile = await getProfileForUser({ authId: user.username });
          if (profile) {
            dispatch(
              setUser({
                firstName: profile.firstName,
                lastName: profile.lastName,
                id: profile.id,
                isNewUser: false,
              }),
            );
          } else {
            setIsOnboarding(true);
          }
        }
      } catch (e) {
        setError(
          "Error loading profile. Please refresh the page to try again.",
        );
        console.log(e);
      }
    };
    loadProfile();
  }, [dispatch]);

  const renderRoutes = () => {
    if (needsAuth) {
      return (
        <Container>
          <Alert severity="error">
            You must be logged in to view this page.
          </Alert>
        </Container>
      );
    }
    if (isOnboarding) {
      return (
        <Routes>
          <Route path="*" element={<Onboarding />} />
        </Routes>
      );
    }
    return <AppRoutes />;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Navigation />
      {error && <Alert severity="error">{error}</Alert>}
      <Container>{renderRoutes()}</Container>
    </QueryClientProvider>
  );
};

export default MainApp;
