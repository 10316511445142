import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  createCategory,
  getCategoriesForOrg,
  updateCategory,
  deleteCategory,
  restoreCategory,
} from "../backend";
import { useParams } from "react-router-dom";
import { Category } from "src/API";
import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

const useCategories = ({ orgId }: { orgId?: string }) => {
  return useQuery(
    ["categories", orgId],
    async () => {
      if (!orgId) {
        return [];
      }
      const categories = await getCategoriesForOrg({ id: orgId });
      return categories;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const Categories = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const {
    data: categories,
    isFetching,
    refetch,
    isLoading,
  } = useCategories({ orgId });

  const [visibleCategories, setVisibleCategories] = useState<Category[]>([]);
  const [showing, setShowing] = useState<"ALL" | "ACTIVE">("ACTIVE");
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);

  const [addingCategory, setAddingCategory] = useState<boolean>(false);
  const [newCategoryName, setNewCategoryName] = useState<string>("");
  const [categoryToDelete, setCategoryToDelete] = useState<Category | null>(
    null,
  );
  const [categoryToRestore, setCategoryToRestore] = useState<Category | null>(
    null,
  );
  const [isCreatingCategory, setIsCreatingCategory] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isRestoring, setIsRestoring] = useState<boolean>(false);
  const [isSavingCategory, setIsSavingCategory] = useState<boolean>(false);
  const [newEditingCategoryName, setNewEditingCategoryName] =
    useState<string>("");

  const handleDeleteCategory = async () => {
    if (!categoryToDelete) {
      return;
    }
    setIsDeleting(true);
    await deleteCategory({ id: categoryToDelete.id });
    setCategoryToDelete(null);
    refetch();
    setIsDeleting(false);
  };

  const handleSaveCategory = async (cat: Category | null) => {
    try {
      if (!cat) {
        return;
      }
      setIsSavingCategory(true);
      const result = await updateCategory({
        id: cat.id,
        name: newEditingCategoryName,
      });
      if (result) {
        setNewCategoryName("");
        setNewEditingCategoryName("");
        refetch();
        setEditingCategory(null);
      }
    } catch (e) {
      console.error("[ERROR] error saving category", e);
    } finally {
      setIsSavingCategory(false);
    }
  };

  const handleCreateCategory = async () => {
    try {
      if (!orgId || !newCategoryName) {
        return;
      }
      setIsCreatingCategory(true);
      const result = await createCategory({
        organizationId: orgId,
        name: newCategoryName,
      });
      if (result) {
        setNewCategoryName("");
        setNewEditingCategoryName("");
        refetch();
        setAddingCategory(false);
      }
    } catch (e) {
      console.error("[ERROR] error saving category", e);
    } finally {
      setIsCreatingCategory(false);
    }
  };

  const handleRestoreCategory = async () => {
    if (!categoryToRestore) {
      return;
    }
    try {
      setIsRestoring(true);
      const result = await restoreCategory({
        id: categoryToRestore.id,
      });
      if (result) {
        refetch();
        setCategoryToRestore(null);
      }
    } catch (e) {
      console.error("[ERROR] error restorying category", e);
    } finally {
      setIsRestoring(false);
    }
  };

  const handlePromptDelete = (cat: Category | null) => {
    setCategoryToDelete(cat);
    setCategoryToRestore(null);
    setEditingCategory(null);
  };

  const handlePromptRestore = (cat: Category | null) => {
    setCategoryToRestore(cat);
    setCategoryToDelete(null);
    setEditingCategory(null);
  };

  useEffect(() => {
    if (categories) {
      const filtered = categories.filter((category) => {
        if (!category) {
          return false;
        }
        if (showing === "ACTIVE") {
          return !category.deletedAt;
        }
        return true;
      });
      setVisibleCategories(filtered || []);
    }
  }, [categories, showing]);

  useEffect(() => {
    if (isDeleting || isRestoring) {
      setEditingCategory(null);
    }
  }, [isRestoring, isDeleting]);

  useEffect(() => {
    if (editingCategory) {
      setNewEditingCategoryName(editingCategory.name);
    } else {
      setNewEditingCategoryName("");
    }
  }, [editingCategory]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}>
        <ButtonGroup>
          <Button
            variant={showing === "ACTIVE" ? "contained" : undefined}
            onClick={() => setShowing("ACTIVE")}>
            Active
          </Button>
          <Button
            variant={showing === "ALL" ? "contained" : undefined}
            onClick={() => setShowing("ALL")}>
            All
          </Button>
        </ButtonGroup>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setAddingCategory(true)}>
          Add New
        </Button>
      </Box>
      <Box sx={{ position: "relative" }}>
        {isFetching && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, .75)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1299,
            }}>
            <Paper sx={{ p: 3 }}>
              <CircularProgress />
            </Paper>
          </Box>
        )}
        {visibleCategories?.map((category) => {
          if (!category) {
            return null;
          }
          return (
            <Card key={`category=${category.id}`} sx={{ mb: 1 }}>
              <CardContent
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, .025)",
                  },
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{
                    opacity: category.deletedAt ? "0.5" : 1,
                    textDecoration: category.deletedAt
                      ? "line-through"
                      : undefined,
                  }}>
                  {category.name}
                </Typography>
                <Button onClick={() => setEditingCategory(category)}>
                  Edit
                </Button>
              </CardContent>
            </Card>
          );
        })}
      </Box>
      <Dialog
        open={Boolean(editingCategory)}
        onClose={() => setEditingCategory(null)}>
        <DialogTitle>Editing Category</DialogTitle>
        <DialogContent>
          <TextField
            value={newEditingCategoryName}
            placeholder="category"
            onChange={(e) => setNewEditingCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Box>
            <LoadingButton
              loading={isSavingCategory}
              variant="contained"
              onClick={() => handleSaveCategory(editingCategory)}>
              Save
            </LoadingButton>
            <Button onClick={() => setEditingCategory(null)}>Cancel</Button>
          </Box>
          <Divider sx={{ my: 1 }} />
          {!editingCategory?.deletedAt && (
            <Button
              onClick={() => handlePromptDelete(editingCategory)}
              color="error">
              Delete
            </Button>
          )}
          {editingCategory?.deletedAt && (
            <Button
              variant="contained"
              onClick={() => handlePromptRestore(editingCategory)}
              color="success">
              Restore
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={addingCategory} onClose={() => setAddingCategory(false)}>
        <DialogTitle>New category</DialogTitle>
        <DialogContent>
          <TextField
            value={newCategoryName}
            placeholder="category"
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isCreatingCategory}
            onClick={() => handleCreateCategory()}>
            Create
          </LoadingButton>
          <Button onClick={() => setAddingCategory(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(categoryToDelete)}
        onClose={() => setCategoryToDelete(null)}>
        <DialogTitle>Deleting Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category? This will remove it
            from all existing transactions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isDeleting}
            onClick={() => handleDeleteCategory()}
            color="error">
            Delete
          </LoadingButton>
          <Button onClick={() => setCategoryToDelete(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(categoryToRestore)}
        onClose={() => setCategoryToRestore(null)}>
        <DialogTitle>Restoring Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to restore this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isRestoring}
            onClick={() => handleRestoreCategory()}
            color="success">
            Restore
          </LoadingButton>
          <Button onClick={() => setCategoryToRestore(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Categories;
