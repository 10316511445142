import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { getOrg, updateOrg } from "../backend";
import { useParams } from "react-router-dom";
import PhotoCropper from "src/components/PhotoCropper";
import { getImageUrl } from "src/utils/images";
import usePlacesAutocomplete from "use-places-autocomplete";
import { VenueSelection } from "../New/NewOrgAddress";
import { Close } from "@mui/icons-material";

const useOrg = (orgId?: string | null) => {
  return useQuery(
    ["org", orgId],
    async () => {
      if (!orgId) {
        return null;
      }
      const org = await getOrg({ id: orgId });
      return org;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const General = () => {
  const { orgId } = useParams();
  const { data: org, isLoading, refetch: refecthOrg } = useOrg(orgId);

  // Form state
  const [newName, setNewName] = useState<string>("");
  const [newDescription, setNewDescription] = useState<string>("");
  const [newHasCustomDonation, setNewHasCustomDonation] = useState(false);
  const [newLogoUrl, setNewLogoUrl] = useState<string | null>(null);

  // Flags
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Places
  const [displayAddress, setDisplayAddress] = useState(
    org?.street1 && org?.city && org?.state
      ? `${org?.street1} ${org?.city}, ${org?.state}`
      : "",
  );
  const [newStreet, setNewStreet] = useState(org?.street1);
  const [newCity, setNewCity] = useState(org?.city);
  const [newState, setNewState] = useState(org?.state);

  const [isProcessing, setIsProcessing] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Places searcher
  const {
    suggestions: { loading: placesLoading, data: placeSuggestions },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 750,
  });

  const handleClubAddressChange = useCallback(
    (address: string) => {
      setIsProcessing(true);
      setValue(address);
    },
    [setValue],
  );

  const handleChangeQuery = (value: string) => {
    setDisplayAddress(value);
    handleClubAddressChange(value);
  };

  const handleVenueSelected = async (venue: VenueSelection) => {
    const { types, structured_formatting } = venue;

    let address = "";
    let city = "";
    let state = "";
    const addressParts = structured_formatting.secondary_text.split(",");

    if (types.includes("street_address") || types.includes("premise")) {
      address = structured_formatting.main_text;
      city = addressParts[0] || "";
      state = addressParts[1] || "";
    } else if (types.includes("locality")) {
      city = structured_formatting.main_text;
      state = addressParts[0] || "";
    } else {
      address = addressParts[0] || "";
      city = addressParts[1] || "";
      state = addressParts[2] || "";
    }

    address = address ? address.trim() : "";
    city = city ? city.trim() : "";
    state = state ? state.trim() : "";

    clearSuggestions();

    setDisplayAddress(
      [address, city, state].filter((s) => s.length > 0).join(", "),
    );

    setNewStreet(address);
    setNewCity(city);
    setNewState(state);

    setIsProcessing(true);

    // TODO: determine if we want to use lat long for searching
    // const results = await getGeocode({ address: venue.description });
    // if (results.length > 0) {
    //   try {
    //     const { lat, lng } = await getLatLng(results[0]);
    //   } catch (_e) {
    //     const e = _e as Error;
    //     console.log("Error setting address", e);
    //     window.alert(
    //       "There was an error setting the address. Please select a new venue.",
    //     );
    //   } finally {
    //     setIsProcessing(false);
    //   }
    // }
  };

  const handleChangeName = (val: string) => {
    setNewName(val);
  };
  const handleChangeDescription = (val: string) => {
    setNewDescription(val);
  };

  const handleUploadImage = (image: string) => {
    const fullUrl = getImageUrl(image);
    setNewLogoUrl(fullUrl);
  };

  const handleSave = async () => {
    if (!org) {
      return;
    }
    setIsSaving(true);
    try {
      const result = await updateOrg({
        id: org.id,
        name: newName,
        description: newDescription,
        hasCustomDonation: newHasCustomDonation,
        logo: newLogoUrl,
        street1: newStreet,
        city: newCity,
        state: newState,
      });
      if (result) {
        setSuccess("Successfully updated organization");
        refecthOrg();
        setTimeout(() => {
          setSuccess(null);
        }, 3000);
      }
    } catch (e) {
      console.log("[ERROR] error updating org", e);
      setError("Error updating org");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (!org) {
      return;
    }
    setNewName(org.name);
    setNewDescription(org.description || "");
    setNewHasCustomDonation(org.hasCustomDonation || false);
    setNewLogoUrl(org.logo || null);
  }, [org]);

  useEffect(() => {
    if (isProcessing && placeSuggestions) {
      setIsProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeSuggestions]);

  const renderPlacesResult = () => {
    if (isProcessing || placesLoading) {
      return (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CircularProgress />
        </Box>
      );
    }
    if (placeSuggestions && placeSuggestions.length > 0) {
      return (
        <Paper elevation={3} sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              onClick={() => {
                clearSuggestions();
              }}
              startIcon={<Close />}>
              close
            </Button>
          </Box>
          {placeSuggestions.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <Button
                key={place_id}
                fullWidth
                sx={{
                  borderBottom: "1px solid #ccc",
                  borderRadius: 1,
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  mt: 1,
                }}
                onClick={() => handleVenueSelected(suggestion)}>
                <Typography fontWeight="bold" component="div">
                  {main_text}
                </Typography>
                <Typography variant="caption">({secondary_text})</Typography>
              </Button>
            );
          })}
        </Paper>
      );
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      {error && (
        <Alert sx={{ mb: 2 }} severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert
          sx={{ mb: 2 }}
          severity="success"
          onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}
      <Box sx={{ position: "relative" }}>
        {isSaving && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 99999,
              backgroundColor: "rgba(0,0,0,.5)",
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Paper sx={{ p: 3 }}>
              <CircularProgress />
            </Paper>
          </Box>
        )}
        <TextField
          label="name"
          fullWidth
          disabled={isSaving}
          sx={{ mb: 2 }}
          value={newName}
          name="name"
          onChange={(e) => handleChangeName(e.target.value)}
        />
        <TextField
          label="About"
          fullWidth
          disabled={isSaving}
          sx={{ mb: 2 }}
          multiline
          value={newDescription}
          name="description"
          onChange={(e) => handleChangeDescription(e.target.value)}
        />

        <TextField
          label="Address"
          fullWidth
          autoComplete="off"
          disabled={isSaving}
          sx={{ mb: 2 }}
          value={displayAddress}
          name="address"
          onChange={(e) => handleChangeQuery(e.target.value)}
        />
        {renderPlacesResult()}

        <FormControlLabel
          value={newHasCustomDonation}
          control={
            <Switch
              disabled={isSaving}
              checked={newHasCustomDonation}
              onChange={() => setNewHasCustomDonation((s: boolean) => !s)}
            />
          }
          label="Show a Misc Donation Option"
        />
      </Box>

      <Divider sx={{ my: 2 }} />
      <Box>
        {newLogoUrl && (
          <img
            src={newLogoUrl}
            alt="logo"
            style={{
              maxWidth: "150px",
              maxHeight: "200px",
              width: "100%",
              objectFit: "contain",
            }}
          />
        )}
        <PhotoCropper
          onCancel={() => null}
          showPreviewSquare={true}
          onComplete={handleUploadImage}
          aspect={1}
          containerSx={{ width: { xs: "100%", md: "550px" } }}
          imageSx={{ width: { xs: "100%", md: "400px" } }}
          keyFolder="org-logos"
        />
      </Box>
      <Divider sx={{ my: 2 }} />
      <LoadingButton
        variant="contained"
        fullWidth={isMobile}
        loading={isSaving}
        onClick={handleSave}>
        Save
      </LoadingButton>
    </Container>
  );
};

export default General;
