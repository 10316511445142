import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import type { User } from "src/API";
import * as queries from "./queries";

export const getProfileForUser = async ({
  authId,
}: {
  authId: string;
}): Promise<User | null> => {
  const result = (await API.graphql(
    graphqlOperation(queries.getUser, { id: authId }),
  )) as GraphQLResult<{
    getUser: User;
  }>;

  if (result.data?.getUser) {
    return result.data.getUser;
  }
  return null;
};
