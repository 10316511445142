import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import { clearState, useGetState } from "./state";
import { createOrganization } from "../backend";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useQueryClient } from "@tanstack/react-query";

const NewOrgConfirm = () => {
  const { isFetching, state } = useGetState();

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clientQuery = useQueryClient();

  const navigate = useNavigate();

  const handleConfirmCreate = async () => {
    try {
      if (!state || !state.name) {
        setError("Please enter a name");
        return;
      }
      const result = await createOrganization({
        name: state?.name,
        description: state?.description,
        street1: state?.street1,
        street2: state?.street2,
        city: state?.city,
        state: state?.state,
        zip: state?.zip,
        logo: state?.logo,
      });
      if (result) {
        await Auth.currentAuthenticatedUser({ bypassCache: false });
        clientQuery.invalidateQueries(["my-orgs"]);
        await clearState();
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          navigate(`/orgs`);
        }, 2000);
      }
    } catch (error) {
      console.log("[ERROR] error creating org", error);
    }
  };

  if (isFetching) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Typography>Does this look correct?</Typography>

      {error && (
        <Alert sx={{ my: 2 }} severity="error">
          {error}
        </Alert>
      )}

      <Paper sx={{ mb: 2, p: 1 }}>
        <Typography>Org Name: {state?.name}</Typography>
      </Paper>
      <Paper sx={{ mb: 2, p: 1 }}>
        <Typography>Org About: {state?.description}</Typography>
      </Paper>
      <Paper sx={{ mb: 2, p: 1 }}>
        <Typography>Org Address: {state?.displayAddress}</Typography>
      </Paper>
      <Paper sx={{ mb: 2, p: 1 }}>
        <Typography>Org Logo:</Typography>
        {state?.logo && (
          <img
            src={state.logo}
            alt="logo"
            style={{ maxHeight: "200px", width: "100%", objectFit: "contain" }}
          />
        )}
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Button
          variant="contained"
          size="large"
          onClick={() => handleConfirmCreate()}>
          Create Organization
        </Button>
      </Box>
      <Dialog open={isLoading}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Creating organization
          </Typography>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NewOrgConfirm;
