import { Box, Button, CircularProgress } from "@mui/material";
import PhotoCropper from "src/components/PhotoCropper";
import { getImageUrl } from "src/utils/images";
import { setState, useGetState } from "./state";
import { useEffect, useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const NewOrgLogo = () => {
  const { isFetching, state } = useGetState();
  const [newLogoUrl, setNewLogoUrl] = useState<string | null>(
    state?.logo || null,
  );
  const navigate = useNavigate();
  const handleUploadImage = (image: string) => {
    const fullUrl = getImageUrl(image);
    setNewLogoUrl(fullUrl);
  };

  useEffect(() => {
    if (state && state.logo) {
      setNewLogoUrl(state.logo);
    }
  }, [state]);
  return (
    <Box>
      {isFetching && <CircularProgress />}
      {newLogoUrl && (
        <img
          src={newLogoUrl}
          alt="logo"
          style={{
            maxHeight: "200px",
            maxWidth: "400px",
            objectFit: "contain",
          }}
        />
      )}
      <PhotoCropper
        onCancel={() => null}
        showPreviewSquare={true}
        onComplete={handleUploadImage}
        aspect={1}
        containerSx={{ width: { xs: "100%", md: "550px" } }}
        imageSx={{ width: { xs: "100%", md: "400px" } }}
        keyFolder="org-logos"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
        <Button
          variant="contained"
          sx={{ mt: 1 }}
          endIcon={<ChevronRight />}
          onClick={() => {
            setState({
              logo: newLogoUrl || null,
            });
            navigate("/orgs/new/confirm");
          }}>
          {newLogoUrl ? "Next" : "Skip"}
        </Button>
      </Box>
    </Box>
  );
};

export default NewOrgLogo;
